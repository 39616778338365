import { authFail, authSuccess } from '@actions';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Button, Grid, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

export default function Login() {
    const { t } = useTranslation();
    const { accessToken } = useSelector(state => state.general);
    const styles = useStyles();
    const classes = useStyles();
    const theme = useTheme();
    const [state, setState] = useState({
        username: '',
        password: '',
        showPassword: false,
        type: 'web',
    });
    const [inputErrors, setInputErrors] = useState();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/"; 
    let fullUrl_from = location.state?.from?.fullUrl || null
    
    useEffect(() => {
        if(accessToken){
            navigate('/');
        }
    }, []);

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        postUrl('/login', state).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                if(fullUrl_from !== null){
                    window.location.replace(fullUrl_from)
                }else{
                    navigate(from, { replace: true });
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Grid
            component="form"
            onSubmit={loginUser}
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: theme.palette.primary.main
            }}
        >
            <Grid item xs={12} style={{marginTop: 50}}>
                <center>
                    <img
                    src="images/logos/logo-bg.png"
                    style={{ width: 150, margin: '10px 0 15px' }}
                    alt="logo"
                    />
                </center>
            </Grid>

            <Grid container direction="row" style={{ paddingLeft: 50, paddingRight: 50, background: 'white', borderTopLeftRadius: 70, height: '100vh', display: 'flex'}}>
                <Grid item xs={12}>
                    <Grid item xs={12} style={{ marginBottom: 30, marginTop: 30, textAlign: 'center' }}>
                        <div>
                            <Typography
                                variant="h3"
                                style={{
                                    fontWeight: 'bold',
                                    borderBottomLeftRadius: '10px', // Set the left bottom corner radius
                                    paddingBottom: '10px', // Add padding to the bottom of the title
                                    textTransform: 'uppercase',
                                    fontSize: '25px'
                                }}
                            >
                                {t('title.login')}
                            </Typography>
                        </div>

                        <div>
                            <Typography variant="body1" style={{ color: 'black' }}>
                                {t('login.desc')}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="filled"
                            label={t('user.mobile')}
                            fullWidth
                            autoComplete="username"
                            color='primary'
                            value={state.username}
                            onChange={({ target }) => setState({ ...state, username: target.value })}
                            className={`${styles.fullWidth} ${styles.marginBottom}`}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="filled"
                            label={t('user.password')}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                            fullWidth
                            type={state.showPassword ? 'text' : 'password'}
                            color='primary'
                            value={state.password}
                            onChange={({ target }) => setState({ ...state, password: target.value })}
                            className={`${styles.fullWidth} ${styles.marginBottom}`}
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="flex-end" // Align to the right
                        alignItems="center"
                        style={{ paddingBottom: 5 }}
                    >
                        <Link
                            component={RouterLink}
                            to={`/forgot-password`}
                            state={{ from: location }}
                            style={{ textDecoration: 'none', marginBottom: '10px' }}
                            color='inherit'
                        >
                            <Typography className={classes.customTypography}>{t('forgotPassword.title')}</Typography>
                        </Link>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} style={{ marginTop: 'auto' }}>
                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ marginTop: 'auto' }} // Add padding to the top
                    >
                        <Link
                            component={RouterLink}
                            to={`/register`}
                            state={{ from: location }}
                            style={{ textDecoration: 'none' }}
                            color='inherit'
                        >
                            <Typography style={{ fontWeight: 400, fontSize: '14px', lineHeight: '16.94px' }}>
                                {t('login.createAcc')}
                            </Typography>
                        </Link>
                    </Grid>

                    <Grid item xs={12} style={{ padding: '10px 0 15px 0' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            style={{ width: '100%' }}
                            className={`${styles.fullWidth} ${styles.marginBottom}`}
                        >
                            {t('button.login')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    marginTop30: {
        marginBottom: '30px !important',
        margin: 10
    },
    fullWidth: {
        width: '100%',
        marginBottom: '20px', // Adjust the value as needed
    },
    customTypography: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16.94px'
    },
}));