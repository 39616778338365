import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Grid, Dialog, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import MerchantItem from '@components/MerchantItem';
import Receipt from '@layouts/Receipt';

const TransactionItem = props => {
    const { transaction } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [chipSetting, setChipSetting] = useState({
        bgColor: '#006638',
        color: '#fff',
        text: t('transfer.completed'),
    });
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Extract the decimal value from the wallet data, default to 2 if not available
    const decimalPlaces = transaction?.wallet_balance?.wallet?.decimal || 2;

    // Format the amount with the appropriate number of decimal places
    const formattedAmount = parseFloat(transaction?.amount).toFixed(decimalPlaces);

    // Determine the sign and color based on the transaction.factor
    const sign = transaction?.factor === 1 ? '+' : (transaction?.factor === -1 ? '-' : '');
    const color = transaction?.factor === -1 ? theme.palette.negativeAmount : 'inherit';

    // Date and time format
    const dateObject = new Date(transaction?.created_at);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString('default', { month: 'short' });
    const year = dateObject.getFullYear();
    const time = dateObject.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const formattedDate = `${day} ${month} ${year}, ${time}`;

    const transactionIcon = () => {
        let img = '../images/icons/empty.png';
        switch (transaction.transaction_type) {
            case 'payment':
            case 'payment-reverse':
                img = '../images/icons/purchase.png'; break;
            case 'promotion':
            case 'promotion-reverse':
                img = '../images/icons/reward.png'; break;
            case 'member-topup':
            case 'member-topup-reverse':
                img = '../images/icons/topup.png'; break;
            case 'transfer':
            case 'convert':
                img = '../images/icons/transfer.png'; break;
            case 'withdraw':
            case 'withdraw-refund':
                img = '../images/icons/withdrawal.png'; break;
            default: break;
        }

        const iconSize = isSmallScreen ? 75 : 85; // Adjust size based on screen size

        return (
            <div style={{ height: iconSize, width: iconSize }}>
                <MerchantItem shopLogo={img} />
            </div>
        );
    }

    return (
        <Box>
            <Grid container alignItems="center" onClick={() => setDialogOpen(true)}>
                <Grid item xs={3.5}>
                    {transactionIcon()}
                </Grid>

                <Grid item xs={8.5}>
                    {/* Date and time */}
                    <Typography variant='caption' style={{color: "#747474"}}>
                        {formattedDate}
                    </Typography>

                    {/* Payment/Transfer/Convert details */}
                    <div>
                        {transaction?.transaction_type === 'payment' && (
                            <Typography variant='caption' style={{ color: '#747474' }}>
                                {t('Payment to')} {transaction?.wallet_transactionable?.branch?.branch_name}
                            </Typography>
                        )}

                        {(transaction?.transaction_type === 'transfer' || transaction?.transaction_type === 'convert') && (
                            <Typography variant='caption' style={{ color: '#747474' }}>
                                {transaction.factor === 1
                                    ? `${t('Receive from')} ${transaction?.details?.from}`
                                    : `${t('Transfer to')} ${transaction?.details?.to}`
                                }
                            </Typography>
                        )}
                    </div>

                    {/* type and Amount */}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                        {/* Type */}
                        <Typography variant='subtitle2' style={{ fontWeight: "bold", textAlign: "left" }}>
                            {transaction?.transaction_code_display?.details[i18n.language] ? transaction?.transaction_code_display?.details[i18n.language] : transaction?.transaction_code_display?.details?.en}
                        </Typography>


                        {/* Amount */}
                        <Typography variant='subtitle2' style={{ fontWeight: "bold", textAlign: "right", color: color }}>
                            {sign}{formattedAmount}
                        </Typography>
                    </div>

                    {/* Remarks */}
                    <div>
                        {transaction && transaction.remark && (
                            <div>
                                {transaction.remark.admin_remark && (
                                    <div>
                                        <Typography noWrap style={{ color: "#2B2B2B", fontSize: "12px" }}>{t('transactionListing.adminRemark')}{transaction.remark.admin_remark}</Typography>
                                    </div>
                                )}

                                {transaction.remark.member_remark && (
                                    <div>
                                        <Typography noWrap style={{ color: "#2B2B2B", fontSize: "12px" }}>{t('transactionListing.memberRemark')}{transaction.remark.member_remark}</Typography>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Grid>

            </Grid>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                disableScrollLock={true}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                }}
            >
                <Box style={{marginTop: 20}}>
                    <Receipt shop={transaction?.shop} transaction={transaction} chipSetting={chipSetting} subTitle={transaction?.wallet_transactionable?.branch?.branch_name}/>
                </Box>
            </Dialog>
        </Box>
    )
}

export default TransactionItem;