import {
    STORE_PROFILE,
    RESET_PROFILE,
} from '../actions/types';

const INITIAL_STATE = {
    id: null,
    username: '',
    name: '',
    email: '',
    mobile: '',
    status: '',
    two_factor_confirmed_at: '',
    profile_image: '',
    crypto_address: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return {
                ...state,
                id: actions.payload.id,
                username: actions.payload.username,
                name: actions.payload.name,
                email: actions.payload.email,
                mobile: actions.payload.mobile,
                status: actions.payload.status,
                two_factor_confirmed_at: actions.payload.two_factor_confirmed_at,
                profile_image: actions.payload.profile_image,
                crypto_address: actions.payload.crypto_address,
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}