import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import WalletItem from '@components/WalletItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const WalletSliderShow = (props) => {
  const { listing, onActiveIndexChange  } = props;
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState(null);
  const [decodedParams, setDecodedParams] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let wallet_bg_color = _.split(process.env.REACT_APP_WALLET_BG_COLOR, /[\[\]", ']+/).filter(str => str !== '');

  useEffect(() => {
    if (decodedParams && swiper) {
      const matchingIndex = listing.findIndex((data) => {
        return (
          data.shop?.id === parseInt(decodedParams.shop_id) &&
          data.wallet_id === parseInt(decodedParams.wallet_id)
        );
      });       
  
      if (matchingIndex !== -1) {
        swiper.slideTo(matchingIndex);
      }
    }
  }, [decodedParams, listing, swiper]);
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shop_id = params.get('s');
    const wallet_id = params.get('w');
  
    if (shop_id && wallet_id) {
      setDecodedParams({
        shop_id: atob(shop_id),
        wallet_id: atob(wallet_id),
      });
    }
  }, []);

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    const selectedWalletID = listing[activeIndex].wallet_id;
    const selectedShopID = listing[activeIndex].shop_id;
    
    // Update the URL with selected parameters
    navigate(`/wallet?s=${btoa(selectedShopID)}&w=${btoa(selectedWalletID)}`);

    // Invoke the callback to pass the active index to the parent component
    if (onActiveIndexChange) {
      onActiveIndexChange(activeIndex);
    }
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleDrawerOpen = (data) => {
    setSelectedData(data);
    setDrawerOpen(true);
  };
  
  const handleDrawerClose = () => {
    setSelectedData(null);
    setDrawerOpen(false);
  };  

  // const drawerContent = (data) => (
  //   <Grid item xs={12} md={6}>
  //     <Typography sx={{ mt: 4, mb: 2, pl: 2 }} variant="subtitle1" component="div" style={{ color: "#A9A9A9" }}>
  //       {t('general.pleaseSelect')}
  //     </Typography>
  //     <List dense={false}>
  //       {/* Use Points */}
  //       <RouterLink to={`/select-branch?s=${btoa(data.shop?.id)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
  //         <ListItem button style={{ color: "#2C344B" }}>
  //           <ListItemText primary="Use Points" />
  //           <IconButton edge="end" aria-label="Use Points">
  //             <KeyboardArrowRightIcon style={{ color: "#2C344B" }} />
  //           </IconButton>
  //         </ListItem>
  //       </RouterLink>
  
  //       {/* Transfer Points */}
  //       <ListItem style={{ color: "#2C344B" }}
  //         secondaryAction={
  //           <IconButton edge="end" aria-label="Transfer Points">
  //             <KeyboardArrowRightIcon style={{ color: "#2C344B" }} />
  //           </IconButton>
  //         }
  //       >
  //         <ListItemText primary="Transfer Points" />
  //       </ListItem>
  //     </List>
  //   </Grid>
  // );

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center' }}>
      <Swiper
        spaceBetween={30}
        slidesPerGroup={1}
        loop={false}
        pagination={{ 
          clickable: true,
          el: '.wallet-slider',
         }}
        slidesPerView={1}
        modules={[Pagination]}
        style={{ width: '-webkit-fill-available' }}
        onSlideChange={handleSlideChange}
        onSwiper={setSwiper}
        className="wallet-swiper"
      >
        {listing.map((data, dataIndex) => {
          const setBgIndex = dataIndex % 3;
          const labelColor = setBgIndex === 0 ? '#000000' : '#ffffff';

          return (
            <SwiperSlide key={dataIndex}>
              <div>
                <WalletItem
                  shopName={data.shop?.code}
                  shopLogo={data.shop?.logo_image?.file_name}
                  walletValue={data.balance}
                  walletDecimal={data.wallet.decimal} walletType={data?.wallet?.name?.[i18n.language] ? data?.wallet?.name?.[i18n.language] : data?.wallet?.name?.en}
                  backgroundColor={wallet_bg_color?.[setBgIndex]}
                  labelColor={labelColor}
                  style={{ transform: 'none' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', position: 'relative', justifyContent: 'space-around', top: 0 }} className="wallet-action">
                    <Button
                      component={RouterLink}
                      to={`/merchant-detail/${data.shop?.id}`}
                      className={`${styles.button} ${isSmallScreen && styles.smallButton}`}
                    >
                      <Typography variant='subtitle2'>
                        {t('button.viewDetails')}
                      </Typography>
                    </Button>
                    <Button
                      component={RouterLink}
                      to={`/select-branch?s=${btoa(data?.shop?.id)}&w=${btoa(data?.wallet_id)}`}
                      className={`${styles.button} ${isSmallScreen && styles.smallButton}`}
                    >
                      <Typography variant='subtitle2'>
                        {t('general.use')} {data?.wallet?.name?.[i18n.language] || data?.wallet?.name?.en}
                      </Typography>
                    </Button>
                  </div>
                </WalletItem>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="wallet-slider"/>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    width: '150px',
    height: '39px',
    borderRadius: '10px',
    background: 'rgba(247, 247, 247, 0.80)',
    boxShadow: '3px 3px 4px 0px rgba(16, 16, 16, 0.10)',
    backdropFilter: 'blur(5px)',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      background: 'rgba(247, 247, 247, 1)', 
      color: theme.palette.primary.main, 
    },
  },
  smallButton: {
    width: '130px',
    height: '39px',
    '@media (min-width: 280px)': {
      width: 112,
    }
  },
}));

export default WalletSliderShow;