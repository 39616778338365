import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const PasswordProfile = () => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        password: false,
        passwordConfirmation: false,
    });
    const [state, setState] = useState({
        currentPassword: "",
        password: "",
        passwordConfirmation: ""
    });
    const [inputErrors, setInputErrors] = useState({});

    const { addAlert, setLoading } = useNotificationLoading();
    const styles = useStyles();
    const { t } = useTranslation();
    const { id } = useSelector(state => state.user);

    const handleChange = ({ target }) => {
        const { name, value } = target;

        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updatePassword = () => {
        setLoading(true);
        setInputErrors();
        let params = {
            current_password:state.currentPassword,
            password:state.password,
            password_confirmation:state.passwordConfirmation
        }
        putUrl(`/user/password`, params).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ password: "", passwordConfirmation: "" });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Grid
            style={{
                paddingLeft: 25,
                paddingRight: 25,
                display: 'flex',
                flexDirection: 'column',
                marginTop: 80,
                overflowY: 'hidden',
                justifyContent: 'space-between',
                height: '85vh',
                alignItems:'stretch'
            }}
        >
            <Grid item xs={12}>
                <Typography variant="h6" style={{ fontWeight: 700, margin: '20px 0 20px 0' }}>
                    {t("passwordProfile.title")}
                </Typography>
            </Grid>

      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
            variant="filled"
            fullWidth
            name="currentPassword"
            label={t('user.oldPassword')}
            type={showPassword.currentPassword ? 'text' : 'password'}
            value={state.currentPassword || ''}
            onChange={handleChange}
            helperText={inputErrors && inputErrors.current_password ? inputErrors.current_password : ''}
            error={inputErrors && inputErrors.current_password ? true : false}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => handleClickShowPassword('currentPassword')}
                        // onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword.currentPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
            variant="filled"
            fullWidth
            name="password"
            label={t('user.newPassword')}
            type={showPassword.password ? 'text' : 'password'}
            value={state.password || ''}
            onChange={handleChange}
            helperText={inputErrors && inputErrors.password ? inputErrors.password : t('info.passwordCondition', { min: 2, max: 12 })}
            error={inputErrors && inputErrors.password ? true : false}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => handleClickShowPassword('password')}
                        // onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword.password ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
            variant="filled"
            fullWidth
            name="passwordConfirmation"
            label={t('user.confirmPassword')}
            type={showPassword.passwordConfirmation ? 'text' : 'password'}
            value={state.passwordConfirmation || ''}
            onChange={handleChange}
            helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
            error={inputErrors && inputErrors.password_confirmation ? true : false}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => handleClickShowPassword('passwordConfirmation')}
                        // onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword.passwordConfirmation ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: 'auto' }}>
        <Button variant="contained" onClick={updatePassword} style={{width: '100%'}}>{t('button.confirm')}</Button>
      </Grid>
    </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },

}));

export default PasswordProfile