import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { useScrollTrigger, Zoom, Fab, Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Typography, Grid, Container } from '@mui/material';
import TransactionItem from '@layouts/TransactionItem';
import WalletSliderShow from '@pages/Wallet/WalletSliderShow';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const Wallet = (props) => {
    const { accessToken } = useSelector((state) => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { t } = useTranslation();
    const [walletSliderShow, setWalletSliderShow] = useState([]);
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [selectedWalletId, setSelectedWalletId] = useState(null);
    const [selectedShopId] = useState(null);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [state, setState] = useState({
        shop_id: '',
        wallet_id: '',
    });
    
    // Callback function to handle the active index change in WalletSliderShow
    const handleActiveIndexChange = (activeIndex) => {
        // Use the active index to get the corresponding wallet ID and shop ID
        const selectedWalletId = walletSliderShow[activeIndex]?.wallet_id;
        const selectedShopId = walletSliderShow[activeIndex]?.shop_id;

        // Perform any additional logic or state updates if needed
        setSelectedWalletId(selectedWalletId, selectedShopId);

        // Fetch wallet transactions based on the current page and selectedWalletId
        fetchData(selectedWalletId, selectedShopId);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 200,
    });

    // Click the button to scroll to the top
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Fetch wallet transactions based on the current page and selectedWalletId
    const fetchData = (selectedWalletId, selectedShopId) => {
        if (selectedWalletId !== null) {
            setLoading(true);

            // Include selectedWalletId and selectedShopId in the params object
            let params = {
                wallet_id: selectedWalletId,
                shop_id: selectedShopId,
            };
            getUrl(`/member-transaction-list`, params)
                .then((response) => {
                    setLoading(false);
                    if (response.status) {
                        setWalletTransactions(response.data);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                });
        }
    };     

    // fetch all the wallet data
    useEffect(() => {
        setLoading(true);
        if (typeof(searchParams.get("s")) === 'string' && typeof(searchParams.get("w")) === 'string') {
            try {
                let shop_id = parseInt(atob(searchParams.get("s")));
                let wallet_id = parseInt(atob(searchParams.get("w")));
                
                if (!isNaN(shop_id) && !isNaN(wallet_id)) {
                    setState({
                        ...state,
                        shop_id: shop_id,
                        wallet_id: wallet_id,
                    });
                    let apiData = {
                        shop_id: shop_id,
                        wallet_id: wallet_id,
                    }
                    getUrl(`/member-transaction-list`, apiData).then(response => {
                        setLoading(false);
                        if (response.status) {
                            setWalletTransactions(response.data);
                        } else {
                            navigate(`/`);
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                        navigate(`/`);
                    });
                } else {
                    setLoading(false);
                    navigate(`/`);
                }
            } catch (error) {
                setLoading(false);
                navigate(`/`);
            }
        } else {
            setLoading(false);
            navigate(`/`);
        }

        getUrl(`/member-wallets`)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    setWalletSliderShow(response.data);
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
    }, []);

    return (
        <div id="back-to-top-anchor">
            <Container maxWidth="lg" style={{ marginTop: 60 }}>
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <div>
                        {accessToken && _.size(walletSliderShow) > 0 && (
                            <WalletSliderShow
                                listing={walletSliderShow}
                                onActiveIndexChange={handleActiveIndexChange}
                                selectedShopId={selectedShopId}
                                selectedWalletId={selectedWalletId}
                                location={location} // Pass location to WalletSliderShow
                            />
                        )}
                    </div>

                    <Grid container>
                        {/* Left side (Transaction) */}
                        <Grid item xs={6} style={{ paddingTop:10}}>
                            <Typography variant="h6" style={{ fontWeight: 'bold', lineHeight: '1.5', verticalAlign: 'middle' }}>
                                {t('wallet.transaction')}
                            </Typography>
                        </Grid>

                        {/* Right side (All) */}
                        {/* <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle2" style={{ lineHeight: '1.5', verticalAlign: 'middle', marginTop: 10 }}>
                                {t('button.all')}
                            </Typography>
                        </Grid> */}

                        {/* Wallet Listing */}
                        {walletTransactions.map((transaction, key) => (
                            <Grid
                                item
                                xs={12}
                                key={transaction.id}
                                style={{
                                    padding: '10px 0',
                                    borderTop: key !== 0 ? 'dashed 1px #D2D2D2' : 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                <Box>
                                    <TransactionItem
                                        transaction={transaction}
                                        selectedWalletId={selectedWalletId}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    {/* "Scroll to Top" button */}
                    <Zoom in={trigger}>
                        <div
                            onClick={scrollToTop}
                            role="presentation"
                            style={{
                                position: 'fixed',
                                bottom: theme.spacing(2),
                                right: theme.spacing(2),
                            }}
                        >
                            <Fab size="small" aria-label="scroll back to top" style={{ backgroundColor: theme.palette.primary.main }}>
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </div>
                    </Zoom>
                </div>
            </Container>
        </div>
    );
};

export default Wallet;