import { changeLanguage } from '@actions';
import { Box, Button, Collapse, Divider, Drawer, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { removeLoginAccess } from '@utils/ApiAction';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronDown, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const DrawerBar = memo((props) => {
  const { openDrawer, setOpenDrawer } = props;
  const styles = useStyles();
  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { accessToken } = useSelector(state => state.general);
  const [option, setOption] = useState({
    openLang: false,
  })

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  }

  const toggleDrawer = () => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    handleCloseDrawer();
    handleLangClose();
  };

  const logout = () => {
    removeLoginAccess();
    navigate('/');
    handleCloseDrawer();
    handleLangClose();
  };

  const login = () => {
    navigate('/splash-screen');
    handleCloseDrawer();
    handleLangClose();
  };

  const navigateMenu = (path) => {
    navigate(path, { state: { from: location } });
    handleCloseDrawer();
    handleLangClose();
  }

  const handleLangChange = () => {
    setOption({ ...option, openLang: !option?.openLang });
  }

  const handleLangClose = () => {
    setOption({ ...option, openLang: false });
  }

  const changeSystemLanguage = lang => {
    i18n.changeLanguage(lang);
    dispatch(changeLanguage(lang));
    handleLangClose();
  };

  return (
    <Box width="100%" height="100%" id="drawer-container" component="div">
      <Drawer
        anchor='left'
        open={openDrawer}
        variant="temporary"
        onClose={toggleDrawer()}
        elevation={5}
        PaperProps={{ style: { position: "absolute", width: '90%', height: '100vh' } }}
        ModalProps={{
          container: document.getElementById("drawer-container"),
          style: { position: "absolute" },
          keepMounted: false,
        }}
        SlideProps={{
          onExiting: (node) => {
            node.style.transform = "scaleX(1)";
            node.style.transformOrigin = "top right";
          },
          onEnter: (node) => {
            node.style.transform = "scaleX(0)";
            node.style.transformOrigin = "top left ";
          },
        }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '90%' }}>
          <div style={{ width: '80%' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: 20, paddingBottom:30 }}>
              <Grid item xs={6}>
                <IconButton onClick={toggleDrawer()}><BiChevronLeft className="img-style" /></IconButton>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h6'>{t('title.setting')}</Typography>
              </Grid>
            </Grid>
            {
              accessToken &&
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                  <Typography variant="button" style={{ fontWeight: 'bold' }} >{t('title.account')}</Typography>
                </div>
                <div onClick={() => navigateMenu('/profile')} className='listing-animate flex-sb m-b-10 pointer'>
                  <Typography variant="subtitle2">{t('title.editProfile')}</Typography>
                  <BiChevronRight className="img-style" />
                </div>
                <div onClick={() => navigateMenu('/password-profile')} className='listing-animate flex-sb m-b-10 pointer'>
                  <Typography variant="subtitle2">{t('title.changePassword')}</Typography>
                  <BiChevronRight className="img-style" />
                </div>
                <div onClick={() => navigateMenu('/security-password-profile')} className='listing-animate flex-sb m-b-5 pointer'>
                  <Typography variant="subtitle2">{t('title.changeSecurityPassword')}</Typography>
                  <BiChevronRight className="img-style" />
                </div>
              </Box>
            }

            <Divider style={{ borderStyle: 'dashed', margin:'20px 0' }} />

            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                <Typography variant="button" style={{ fontWeight: 'bold' }} >{t('title.general')}</Typography>
              </div>
              <div className='listing-animate flex-sb m-b-10 pointer' onClick={handleLangChange} >
                <Typography variant="subtitle2">{t('general.language')}</Typography>
                {option?.openLang ? <BiChevronDown className="img-style" /> : <BiChevronRight className="img-style" />}
              </div>
              <Collapse in={option?.openLang} timeout="auto" unmountOnExit>
                <div className='flex-c flex-col m-b-20'>
                  <Typography onClick={() => changeSystemLanguage('en')} variant="caption" style={{ cursor: 'pointer', paddingTop: 5 }}>{t('general.en')}</Typography>
                  <Typography onClick={() => changeSystemLanguage('cn')} variant="caption" style={{ cursor: 'pointer', paddingTop: 5 }}>{t('general.cn')}</Typography>
                </div>
              </Collapse>
              {
                accessToken &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, cursor: 'pointer' }} onClick={() => navigateMenu('/invite-friends')} className='listing-animate'>
                  <Typography variant="subtitle2">{t('title.inviteFriend')}</Typography>
                  <BiChevronRight className="img-style" />
                </div>
              }
              {
                accessToken ?
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, cursor: 'pointer' }} onClick={() => navigateMenu('/invite-friends')} className='listing-animate'>
                  <Button variant="contained" onClick={logout}>{t('button.logout')}</Button>
                </div>
                :
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, cursor: 'pointer' }} onClick={() => navigateMenu('/invite-friends')} className='listing-animate'>
                  <Button variant="contained" onClick={login}>{t('button.login')}</Button>
                </div>
              }
            </Box>
          </div>
          {/* <div style={{width:'80%'}}>
            {
              accessToken ?
                <Button variant="contained" onClick={logout}>{t('button.logout')}</Button>
                :
                <Button variant="contained" onClick={login}>{t('button.login')}</Button>
            }
          </div> */}
        </Box>
      </Drawer>
    </Box>
  )
});

const useStyles = makeStyles((theme) => ({
}));

DrawerBar.defaultProps = {
  showTitle: true,
};

export default DrawerBar;