import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

let theme = createTheme({
    palette: {
        primary: {
            main: '#F4C13D',
            secondary: '#8d806f'
        },
        secondary: {
            main: '#202E66',
            secondary: '#cf8f2e',
        },
        green: {
            main: green[500],
        },
        red: {
            main: red[500],
        },
        gray: {
            main: '#848484',
            secondary: '#b5b5b5',
            ltext: '#b7b7b7',
            dtext: '#606060',
            light: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#eaeaea',
        },
        button: {
            main: '#F4C13D',
            borderMain: '2px solid #F4C13D',
            darkRed: '#cd4949',
            green: '#47ba80'
        },
        bottomIconColor: '#ffffff',
        selectedBottomIconColor: '#000000',
        backgroundColor: '#F1F1F1',
        white:'#ffffff',
        inputIconColor:'#000000',
        negativeAmount: '#D49F19',
    },
    components: {
        // Name of the component
        MuiBottomNavigation: {
            styleOverrides: {
                // Name of the slot
                root: {
                    background: '#F7F7F7',
                    height:80,
                    paddingBottom:15,
                    boxShadow:'-1px -4px 8px 0px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: '#000000',
                    height:'inherit'
                },
            },
        },
        MuiBackdrop:{
            styleOverrides:{
                root: {
                    width: 444,
                    margin: '0 auto',
                    height:'100vh'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    color: "#4C4C4C",
                    textTransform:'capitalize',
                    boxShadow:'3px 3px 4px 0px transparent, -3px -3px 4px 0px rgba(0, 0, 0, 0.09) inset',
                    // boxShadow:'3px 3px 4px 0px rgba(0, 0, 0, 0.18), -3px -3px 4px 0px rgba(0, 0, 0, 0.09) inset',
                    background:'linear-gradient(91deg, #F4C13D -7.08%, #FFD466 99.62%)',
                    "&.Mui-disabled": {
                        background: "#CFCFCF",
                    },
                    width: "100%",
                    height: "50px",
                    fontSize: "16px",
                    marginTop: "20px"
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Default state of text input field font.
                    '& .MuiInputBase-input.MuiInput-input': {
                        color: '#000000',
                    },

                    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
                        WebkitTextFillColor: '#000000',
                        backgroundColor:"#F7F7F7",
                    },

                    '& .MuiInputBase-root': {
                        color: '#000000',
                    },

                    '& .MuiFormLabel-root':{
                        color: '#999999',
                    },

                    '& .MuiFormLabel-root.Mui-focused':{
                        color: '#F4C13D',
                    },

                    '& .MuiFormLabel-root.Mui-disabled':{
                        color: '#999999',
                    },

                    // Default state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl::before': {
                        borderBottomColor: 'none',
                    },

                    // On hover state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl:hover::before': {
                        color: '#000000',
                    },

                    // On focus state of underline.
                    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.Mui-focused::after': {
                        borderBottomColor: 'none',
                    },

                    '& .MuiFilledInput-underline:before': {
                        borderBottom: "none",
                    },
                    '& .MuiFilledInput-underline:after': {
                        borderBottom: "none",
                    },
                    '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottom: "none",
                    },

                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                            borderColor: 'transparent',
                            backgroundColor: 'rgba(0, 0, 0, 0.09)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                            backgroundColor: 'rgba(0, 0, 0, 0.09)',
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "#F7F7F7",
                    borderRadius: '10px !important',
                    flexShrink: 0,
                    height: 50,
                    boxShadow:'3px 3px 6px 0px #FFF, 3px 3px 2px 0px rgba(0, 0, 0, 0.07) inset',
                },
                input: {
                    '&:-webkit-autofill': {
                        WebkitTextFillColor: '#000000',
                        borderRadius:'10px !important',
                        height:14,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#000000',
                },
            },
        },
        MuiFilledInput:{
            styleOverrides: {
                root: {
                    backgroundColor: "#F7F7F7",
                },
            },
        },
        // MuiDivider:{
        //     styleOverrides:{
        //         root:{
        //             ":after":{
        //                 borderTop: "thin solid #000000"
        //             },
        //             ":before":{
        //                 borderTop: "thin solid #000000"
        //             },
        //         },
        //     },
        // },
        MuiInputAdornment:{
            styleOverrides:{
                root:{
                    color:'#000000',
                },
            },
        },
        MuiMenu:{
            styleOverrides:{
                paper:{
                    backgroundColor:"#F4C13D",
                    color:'#ffffff',
                }, 
            },
        },
        MuiTab:{
            styleOverrides:{
                root:{
                    color:'#000000',
                },
            },
        },
        MuiTabs:{
            styleOverrides:{
                indicator:{
                    backgroundColor:'#F4C13D',
                },
                scrollButtons:{
                    color:'#000000',
                }
            },
        },
        MuiTooltip:{
            styleOverrides:{
                tooltip:{
                    backgroundColor:'#F4C13D',
                },
                arrow:{
                    color:'#F4C13D',
                },
            },
        },
        MuiIconButton:{
            styleOverrides:{
                root:{
                    color:'#000000',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                        display: 'none',
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        },
        MuiAvatar:{
            styleOverrides:{
                root:{
                    backgroundColor:'#F4C13D',
                },
            },
        },
        MuiAppBar:{
            styleOverrides:{
                root:{
                    backgroundColor:'transparent',
                    boxShadow:'none',
                },
            },
        },
        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    color:"#ffffff",
                },
            },
        },
        MuiDialog:{
            styleOverrides:{
                paper:{
                    // backgroundColor:'#202E66',
                    // gap:16,
                    // borderRadius:12,
                    // alignItems:'center',
                    width:350,
                    maxWidth:'100%'
                },
            },
        },
        // MuiDialogActions:{
        //     styleOverrides:{
        //         root:{
        //             paddingBottom:24,
        //         },
        //     },
        // },
        // MuiListItemButton:{
        //     styleOverrides:{
        //         root:{
        //             backgroundColor: '#202E66',
        //             borderRadius:8,
        //             ':hover':{
        //                 backgroundColor:'#6F5AF4',
        //             }
        //         },
        //     },
        // },
        // MuiListItem:{
        //     styleOverrides:{
        //         root:{
        //            paddingRight:0,
        //            paddingLeft:0,
        //         },
        //     },
        // },
        // MuiListItemIcon:{
        //     styleOverrides:{
        //         root:{
        //             color:'#ffffff',
        //         },
        //     },
        // },
        MuiLink:{
            styleOverrides:{
                root:{
                    ":hover":{
                        color:'#F4C13D',
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: (themeParam) => `
              & .MuiBottomNavigation-root &.Mui-selected {
                color: ${themeParam.palette.selectedBottomIconColor} !important;
              },
              & .MuiTab-root.Mui-selected{
                color: ${themeParam.palette.selectedBottomIconColor} !important;
              },
            `,
        },
    }
});

theme = responsiveFontSizes(theme);

export default theme;