import { makeStyles, useTheme } from '@mui/styles';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeProfile } from '@actions';
import { isUndefined } from 'lodash';
import { putUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

//MUI
import { Button, Grid, TextField, Alert, AlertTitle, Typography, Badge, Avatar, Dialog, DialogTitle, DialogContent, ListItem, ListItemButton, ListItemIcon, ListItemText, List, DialogActions } from '@mui/material/';

import { FiImage } from 'react-icons/fi';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { MdOutlinePermMedia } from "react-icons/md";

import DeleteConfirmationModal from '@layouts/DeleteConfirmationModal';

export default function BasicInfoProfile() {
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();
    const hiddenFileInput = useRef();
    const { username, name, email, mobile, profile_image } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: name,
        email: email,
        mobile: mobile,
        username: username,
        image: null,
        previewUrl: null,
    });
    const [option, setOption] = useState({
        showImageOption: false,
        previewImage: false,
        deleteConfirm: false,
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'username') {
            value = value.toLowerCase();
        }
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        let apiData = {
            name: state.name,
            email: state.email,
            mobile: state.mobile,
        }

        putUrl(`/user`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.editSuccess'), 'success', '');
                setState({
                    ...state,
                    existing_image: response.data.profile_picture,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    //edit profile image
    const openMenu = () => {
        setOption({ ...option, showImageOption: true });
    }

    const closeMenu = () => {
        setOption({ ...option, showImageOption: false });
    }

    const handleImageChange = ({ target }) => {
        setState({ ...state, image: target.files[0] });
        if (!isUndefined(target.files[0])) {
            setOption({ ...option, showImageOption: false, previewImage: true });
        }
        target.value = null;
    }

    useEffect(() => {
        if (state.image) {
            setState({ ...state, previewUrl: URL.createObjectURL(state.image) })
        } else {
            setState({ ...state, previewUrl: null });
        }
    }, [state.image]);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const closePreview = () => {
        setOption({ ...option, previewImage: false });
    }

    const saveProfileImage = () => {
        setInputErrors({});
        closePreview();
        setLoading(true);
        const formData = new FormData();
        formData.append("_method", "put");
        formData.append("profile_image", state.image);

        postUrl('/user/upload-profile-image', formData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.imageUploadSuccess'), 'success', '');
                setState({
                    ...state,
                    image: null,
                    previewUrl: null,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.imageUploadFailed'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const removeProfileImage = () => {
        setLoading(true);
        if (profile_image) {
            putUrl(`/user/${profile_image.id}/delete-profile-image`).then(response => {
                setLoading(false);
                if (response.status) {
                    dispatch(storeProfile(response.data));
                    addAlert('', t('success.imageUploadSuccess'), 'success', '');
                    setState({
                        ...state,
                        image: null,
                        previewUrl: null,
                    });
                    setOption({ ...option, deleteConfirm: false });
                    addAlert('', t('success.imageRemoveSuccess'), 'success', '');
                } else {
                    addAlert('', t('error.imageRemoveFailed'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert('', t('error.contactSupport'), 'error', '');
        }
    }

    return (
        <div className='flex-m flex-col p-lr-15 m-t-60 p-t-30' >
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <Avatar alt="Edit" className={styles.smallAvatarStyle} onClick={openMenu} ><FiImage style={{ color: 'black', height: 15, width: 15, cursor: 'pointer' }} /></Avatar>
                        }
                    >
                        <div className={styles.avatarContainerStyle}>
                            {
                                profile_image ?
                                    <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                    :
                                    (
                                        name ?
                                            <Avatar className={styles.avatarStyle}>
                                                <Typography variant='h3'>{name.split(' ')[0][0]}</Typography>
                                            </Avatar>
                                            :
                                            <Avatar className={styles.avatarStyle} >
                                                <img src="images/profile/profile-image.png" style={{ height: '100%', width: '100%' }} alt="profile-logo" />
                                            </Avatar>
                                    )
                            }
                        </div>
                    </Badge>
                    {
                        inputErrors?.profile_image &&
                        <Typography style={{ color: 'red', paddingTop: 2 }}>{inputErrors?.profile_image}</Typography>
                    }
                </Grid>
                {
                    state?.email === null &&
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <AlertTitle style={{ color: 'red', fontWeight: 'bold' }}>{t('general.warning')}</AlertTitle>
                            <Typography style={{ color: 'black', textAlign: 'justify' }} variant='body2' >{t('user.userEmailWarning')}</Typography>
                        </Alert>
                    </Grid>
                }
                {/* <Grid item xs={12}>
                    <TextField
                        variant="filled"
                        fullWidth
                        name='username'
                        label={t('user.username')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                        error={inputErrors && inputErrors.username ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        disabled
                        value={state.username || ''}
                    />
                    <Typography variant="caption" color="textSecondary">
                        {t('info.usernameLowerCase')}
                    </Typography>
                </Grid> */}
                <Grid item xs={12}>
                    <TextField
                        variant="filled"
                        fullWidth
                        name='name'
                        label={t('user.name')}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                        error={inputErrors && inputErrors.name ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={state.name || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="filled"
                        fullWidth
                        name='email'
                        label={t('user.email')}
                        onChange={handleChange}
                        // required
                        helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                        error={inputErrors && inputErrors.email ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={state.email || ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="filled"
                        fullWidth
                        name='mobile'
                        label={t('user.mobile')}
                        onChange={handleChange}
                        required
                        helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                        error={inputErrors && inputErrors.mobile ? true : false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ disableUnderline: true }}
                        value={state.mobile || ''}
                    />
                </Grid>
                <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" >
                    <Button variant="contained" onClick={submitData} >{t('button.save')}</Button>
                </Grid>
                {/* upload image */}
                <input accept="image/*" className={styles.input} type="file" onChange={handleImageChange} ref={hiddenFileInput} />
                <Dialog open={option?.showImageOption} onClose={closeMenu}>
                    <DialogTitle>{t('dialog.imageOptionTitle')}</DialogTitle>
                    <DialogContent>
                        <List style={{ backgroundColor: theme.palette.dialogContentBg, borderRadius: 12 }}>
                            <ListItem>
                                <ListItemButton style={{ backgroundColor: 'transparent' }} onClick={handleClick}>
                                    <ListItemIcon>
                                        <MdOutlinePermMedia className="img-style" />
                                    </ListItemIcon>
                                    <ListItemText primary={t('dialog.chooseNewImage')} />
                                </ListItemButton>
                            </ListItem>
                            {
                                profile_image &&
                                <ListItem>
                                    <ListItemButton style={{ backgroundColor: 'transparent' }} onClick={() => setOption({ ...option, showImageOption: false, deleteConfirm: true })}>
                                        <ListItemIcon>
                                            <RiDeleteBin7Line className="img-style" />
                                        </ListItemIcon>
                                        <ListItemText primary={t('dialog.deleteProfileImage')} />
                                    </ListItemButton>
                                </ListItem>
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={closeMenu}>{t('button.close')}</Button>
                    </DialogActions>
                </Dialog>
                {/* preview image */}
                <Dialog open={option?.previewImage} onClose={closePreview} >
                    <DialogTitle>{t('dialog.imageOptionTitle')}</DialogTitle>
                    <DialogContent style={{ width: '80%' }}>
                        <List style={{ backgroundColor: theme.palette.dialogContentBg, borderRadius: 12, padding: 10 }}>
                            <ListItem style={{ justifyContent: 'center' }}>
                                <Avatar className={styles.avatarStyle} src={state.previewUrl} />
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={saveProfileImage}>{t('button.save')}</Button>
                        <Button variant="contained" onClick={closePreview}>{t('button.cancel')}</Button>
                    </DialogActions>
                </Dialog>
                <DeleteConfirmationModal open={option.deleteConfirm} closeDeleteDialog={() => setOption({ ...option, deleteConfirm: false })} submitData={removeProfileImage} />
            </Grid>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    avatarStyle: {
        height: 96,
        width: 96,
        flexShrink: 0,
        boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset',
        filter: 'drop-shadow(3px 3px 4px #FFF)',
        objectFit: 'cover',
    },
    smallAvatarStyle: {
        width: 30,
        height: 30,
        boxShadow: '-2px -2px 4px 0px rgba(0, 0, 0, 0.10) inset, 3px 3px 4px 0px rgba(255, 255, 255, 0.57) inset, 2px 2px 5px 0px rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
        bottom:10,
    },
    input: {
        display: "none",
    },
    avatarContainerStyle: {
        flex: 1,
        padding: 10,
        position: 'relative',
        boxShadow: '3px 3px 4px 0px rgba(0, 0, 0, 0.10) inset',
        filter: 'drop-shadow(3px 3px 4px #FFF)',
        borderRadius: 60, 
        backgroundColor:'theme.palette.backgroundColor'
    }
}));