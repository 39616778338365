import _ from 'lodash';
import React from 'react';
import { PropTypes } from 'prop-types';
import { FormControl, Select, Typography, MenuItem, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandLessRounded';

const CustomSelect = props => {
    const { label, listing, listLabel, listValue, placeholder, displayEmpty, menuStyle, textInputStyle, value, onChange, name ,error , helperText } = props;
    const styles = useStyles();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Typography variant='body2' style={{ color: error? '#d32f2f':'#ffffff'}}>{label}</Typography>
            <FormControl fullWidth>
                <Select
                    IconComponent={ExpandMoreRoundedIcon}
                    sx={textInputStyle}
                    MenuProps={{
                        PaperProps: {
                            sx: { ...menuStyle },
                        },
                    }}
                    displayEmpty={displayEmpty}
                    value={value}
                    onChange={onChange}
                    name={name}
                    classes={{
                        icon: styles.selectIcon
                      }}
                >
                    <MenuItem disabled value="">
                        {placeholder}
                    </MenuItem>
                    {_.size(listing) > 0 && listing.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={listValue === 'main' ? option : option[listValue]} >
                            {listLabel === 'main' ? option : option[listLabel]}
                        </MenuItem>
                    ))}
                </Select>
                {
                    error && 
                    <FormHelperText style={{ color:'#d32f2f' , marginLeft:0}} >{helperText}</FormHelperText>
                }
            </FormControl>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    selectIcon: {
        color: "#ffffff",
      },
}));

// DEFINE DEFAULT PROPS TYPE
CustomSelect.propTypes = {
    label: PropTypes.string,
    listing: PropTypes.array,
    listLabel: PropTypes.string,
    listValue: PropTypes.string,
    displayEmpty: PropTypes.bool,
    menuStyles: PropTypes.any,
    textInputStyle: PropTypes.any,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    error:PropTypes.bool,
    helper_text: PropTypes.string,
};

// DEFINE DEFAULT PROPS VALUE
CustomSelect.defaultProps = {
    label: '',
    listing: [],
    listLabel: 'main',
    listValue: 'main',
    displayEmpty: false,
    menuStyle: {
        backgroundColor: '#0D184F',
        marginTop: 0.9,
        "&& .Mui-selected": {
            backgroundColor: '#F4C13D',
        }
    },
    textInputStyle: {
        color: '#ffffff !important',
        backgroundColor: '#F4C13D',
        borderRadius: '8px',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F4C13D',
        },
    },
    onChange: {},
    name: '',
    error:false,
    helperText:'',
};

export default CustomSelect;