import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { Box, Grid, Typography, IconButton, Dialog, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { MdClose, MdPhone, MdEmail } from "react-icons/md";

const MerchantDescItem = props => {
    const { shopInfo, open, handleClose } = props;

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableScrollLock={true}
        >
            <div className='dis-flex flex-col flex-c-m'>
                <Grid container className='p-all-20 s-full' style={{ background: '#fff', maxWidth: 370 }}>
                    <Grid item xs={12} className='dis-flex flex-r'>
                        <IconButton className={styles.infoBoxDesign} onClick={handleClose} style={{ background: '#f4f4f4' }}>
                            <MdClose className='fs-30' style={{ color: '#000' }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} className='p-b-10'>
                        <Typography variant='body1' className='p-r-10'><b>{`${shopInfo?.code}`}</b></Typography>
                        <Typography variant='body2' className='p-r-10'>{shopInfo?.name}</Typography>
                    </Grid>

                    <Grid item xs={12} className='dis-flex flex-m p-b-10'>
                        <MdPhone className='fs-20 m-r-10' />
                        <Typography variant='body2'>{shopInfo?.mobile}</Typography>
                    </Grid>

                    <Grid item xs={12} className='dis-flex flex-m p-b-10'>
                        <MdEmail className='fs-20 m-r-10' />
                        <Link underline="hover" href={`mailto:${shopInfo?.email}`} style={{ color: '#000', fontSize: 14, wordBreak: 'break-word' }}>{shopInfo?.email}</Link>
                    </Grid>

                    <Grid item xs={12} className='p-t-10'>
                        <Typography variant='body2' className='p-b-10'><b>{t('shops.description')}</b></Typography>
                        <Typography style={{ textAlign: 'justify', fontSize: 12, whiteSpace: 'pre-wrap' }}>
                            {shopInfo?.description}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}
export default MerchantDescItem;

const useStyles = makeStyles(theme => ({
    infoBoxDesign: { 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
        borderRadius: 49, 
        width: 28, 
        height: 28,
        boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.10) inset',
        zIndex: 1100,
        padding: 5,
    },
}));