import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Link, Typography, Grid } from '@mui/material';

import MerchantItem from '@components/MerchantItem';

//slider
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';

const MerchantSlider = props => {
    const { listing } = props;
    let location = useLocation();
    const { t } = useTranslation();

    return (
        <>
            <div className='flex-c flex-col p-lr-20' style={{  alignItems: 'flex-start' }}>
                <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: 10, paddingRight: 20 }}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{`${t('title.availableMerchant')} (${_.size(listing) || 0})`}</Typography>
                    <Link to="/merchants" state={{ from: location }} component={RouterLink} underline='none' color="inherit">
                        <Typography variant="body2">{t('button.viewAll')}</Typography>
                    </Link>
                </Box>
            </div>
            {/* <div className='flex-col flex-c' style={{ alignItems: 'flex-start' }}>
                <Swiper
                    spaceBetween={30}
                    slidesPerGroup={1}
                    loop={false}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    modules={[Navigation]}
                    className="merchant-listing"
                    slidesPerView={3.5}
                    breakpoints={{
                        // 300: {
                        //     slidesPerView: 3.5,
                        // },
                        // 600: {
                        //     slidesPerView: 2.5,
                        // },
                        // 900: {
                        //     slidesPerView: 3.5,
                        // },
                    }}
                    style={{ width: '-webkit-fill-available', padding: '0 20px' }}
                >
                    {
                        _.map(listing, (data, dataIndex) => {
                            return (
                                <SwiperSlide key={dataIndex}>
                                    <Link to={`/merchant-detail/${data?.id}`} state={{ from: location }} component={RouterLink} underline='none'>
                                        <MerchantItem shopLogo={data?.logo_image?.file_name} shopName={data.code} />
                                    </Link>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div> */}
            <Grid container spacing={2} className='p-lr-20'>
                {
                    _.map(listing, (data, dataIndex) => {
                        return (
                            <Grid item xs={4}>
                                <Link to={`/merchant-detail/${data?.id}`} state={{ from: location }} component={RouterLink} underline='none'>
                                    <MerchantItem shopLogo={data?.logo_image?.file_name} shopName={data.code} />
                                </Link>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    );
}

export default MerchantSlider;