import React, { memo, useState , useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { BottomNavigation, Paper, BottomNavigationAction } from '@mui/material';
import { BiHomeAlt, BiScan,BiStar } from "react-icons/bi";
import { FiUsers, FiSettings } from "react-icons/fi";
import _ from 'lodash';

const MAIN_NAVIGATE = ['home', 'referral', 'scan', 'star', 'setting'];

const BottomNavigationBar = memo(() => {
    const styles = useStyles();
    let location = useLocation();
    let navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [value, setValue] = useState('home');
    const { accessToken } = useSelector(state => state.general);
    let path = location.pathname.split('/');

    useEffect(() => {
        let temp_value = _.intersection(MAIN_NAVIGATE, path);
        if( _.size(temp_value) > 0){
            setValue(temp_value[0]);
        }else{
            setValue('home');
        }
    }, [path]);

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, maxWidth: 'inherit', width: '100%' }} elevation={3}>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                sx={{
                    // '& .Mui-selected':{
                    //     color: theme.palette.selectedBottomIconColor,
                    // }
                }}
            >
                <BottomNavigationAction to="/" state={{ from: location }} component={RouterLink} label={t('title.home')} icon={<BiHomeAlt className="img-style" />} value="home" />
                <BottomNavigationAction label={t('title.referral')} icon={<FiUsers  className="img-style" value="referral" />} />
                <BottomNavigationAction to="/" label={t('title.scan')} component={RouterLink} icon={<BiScan className="img-style" />} value="scan" />
                <BottomNavigationAction to="/" label={t('title.star')} component={RouterLink} icon={<BiStar className="img-style" />} value="star" />
                <BottomNavigationAction to={accessToken ? "/me" : "/login"} state={{ from: location }} component={RouterLink} label={t('title.setting')} icon={<FiSettings className="img-style" />} value="setting" />
            </BottomNavigation>
        </Paper>
    )
});


const useStyles = makeStyles((theme) => ({
}));

export default BottomNavigationBar;