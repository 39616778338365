import {
    STORE_PROFILE,
    RESET_PROFILE
} from './types';

export const storeProfile = profile => dispatch => {
    dispatch({ type: STORE_PROFILE, payload: profile });
}

export const resetProfile = () => dispatch => {
    dispatch({ type: RESET_PROFILE });
}
