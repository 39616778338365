import React, { memo, useState, useEffect } from 'react';
import { ethers } from "ethers";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { postUrl } from '@utils/ApiAction';
import { authSuccess, authFail } from '@actions';
import useNotificationLoading from '@utils/useNotificationLoading';

import { Grid, TextField, InputAdornment, IconButton, Button, Typography, Link } from '@mui/material';

import { FiCheckCircle } from "react-icons/fi";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import CustomSelect from '@components/CustomSelect';

const INITIAL_STATE = {
    username: '',
    name: '',
    email: '',
    mobile: '',
    password: ' ',
    passwordConfirmation: ' ',
    referralName: '',
    placementName: '',
    placementPosition: 1,
};
const AUTO_LOGIN = true;

const Register = memo(() => {
    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [option, setOption] = useState({
        passwordShow: false,
        passwordConfirmationShow: false,
        successRegister: false,
    });
    const [metaMaskInfo, setMetaMaskInfo] = useState({
        type: 'web',
        userId: null,
    });
    let from = (location.state?.from?.pathname !== "/login" ? location.state?.from?.pathname : "/") || "/";
    const POSITION = [
        { 'code': 'left', 'value': 1, label: t('general.left') },
        { 'code': 'right', 'value': 2, label: t('general.right') }
    ];

    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const referralUsername = urlParams.get('r');

        if (referralUsername) {
            // If referral username is present in the URL, pre-fill and disable the referral username field
            const decodedReferralUsername = atob(referralUsername);
            setState(prevState => ({ ...prevState, referralName: decodedReferralUsername }));
            setInputErrors(prevErrors => ({ ...prevErrors, referral_name: '' })); // Clear any previous error for referral username
        }
    }, []); // Run this effect only once when the component mounts


    useEffect(() => {

        if (option?.successRegister && state?.username !== '' && state?.password !== '' && AUTO_LOGIN) {
            //Implementing the setInterval method 
            const interval = setInterval(() => {
                loginUser();
            }, 1000);

            //Clearing the interval 
            return () => clearInterval(interval);
        }

    }, [option?.successRegister]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'username') {
            value = value.toLowerCase();
        }
        if (name === 'mobile') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const handleSubmit = () => {
        setLoading(true);
        setInputErrors({});
        let params = {
            name: state.name,
            // username: state.username,
            email: state.email,
            mobile: state.mobile,
            password: state.password,
            password_confirmation: state.passwordConfirmation,
            referral_name: state.referralName,
            placement_name: state.placementName,
            placement_position: state.placementPosition
        }
        postUrl('/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                setOption({ ...option, successRegister: !option?.successRegister });
                setMetaMaskInfo({ ...metaMaskInfo, userId: response.data.id });
                setState({ ...state, username: response?.data?.username })
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const loginUser = () => {
        setLoading(true);
        let params = {
            username: state.username,
            password: state.password,
            type: 'web',
        }
        postUrl('/login', params).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(authSuccess(response.data));
                navigate(from, { replace: true });
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Grid container spacing={1} style={{
            paddingLeft: 25, paddingRight: 25, marginTop: 80, display: 'flex',
            flexDirection: 'row', textAlign: 'center', height: option?.successRegister ? 'auto' : 'auto'
        }} >
            <Grid item xs={12}>
                <Typography variant="h4" style={{ fontWeight: 'bold', color: 'black', textAlign: 'center' }}>
                    {t('register.headerTitle')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1" style={{ color: 'black', marginBottom: '20px' }}>
                    {t('register.smallTitle')}
                </Typography>
            </Grid>
            {
                option?.successRegister ?
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <FiCheckCircle style={{ color: theme.palette.primary.main }} fontSize={60} />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant='h5'>{t('success.successRegisterAutoLogin')}</Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid item xs={12} >
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                            <Grid item xs={12}>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name='name'
                                    label={t('user.name')}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                    error={inputErrors && inputErrors.name ? true : false}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name='email'
                                    label={t('user.email')}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                    error={inputErrors && inputErrors.email ? true : false}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name='mobile'
                                    label={t('user.mobile')}
                                    onChange={handleChange}
                                    required
                                    helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                    error={inputErrors && inputErrors.mobile ? true : false}
                                    InputProps={{ disableUnderline: true }}
                                    value={state?.mobile}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name='referralName'
                                    label={t('user.referralUsername')}
                                    onChange={handleChange}
                                    helperText={inputErrors && inputErrors.referral_name ? inputErrors.referral_name : ''}
                                    error={inputErrors && inputErrors.referral_name ? true : false}
                                    InputProps={{ disableUnderline: true }}
                                    value={state.referralName}
                                    disabled={!!urlParams.get('r')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name='password'
                                    label={t('user.password')}
                                    onChange={handleChange}
                                    type={option?.passwordShow ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handlePasswordShow('passwordShow')}>
                                                    {option?.passwordShow ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true
                                    }}
                                    helperText={inputErrors && inputErrors.password ? inputErrors.password : t('info.passwordCondition', { min: 2, max: 12 })}
                                    error={inputErrors && inputErrors.password ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="filled"
                                    fullWidth
                                    name='passwordConfirmation'
                                    label={t('user.confirmPassword')}
                                    onChange={handleChange}
                                    type={option?.passwordConfirmationShow ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handlePasswordShow('passwordConfirmationShow')}>
                                                    {option?.passwordConfirmationShow ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true
                                    }}
                                    helperText={inputErrors && inputErrors.confirm_password ? inputErrors.confirm_password : ''}
                                    error={inputErrors && inputErrors.confirm_password ? true : false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
            }
            {option?.successRegister ? null : (
                <Grid item xs={12} style={{ marginTop: 80,marginBottom: 20}}>
                    <Link
                        component={RouterLink}
                        to={`/login`}
                        state={{ from: location }}
                        style={{ textDecoration: 'none', marginBottom: '10px' }}
                        color="inherit"
                    >
                        <Typography style={{ fontWeight: 400, fontSize: '14px', lineHeight: '16.94px', marginBottom: 10 }}>
                            {t('register.backToLogin')}
                        </Typography>
                    </Link>

                    <Button variant="contained" onClick={handleSubmit} style={{ width: '100%' }}>
                        {t('button.confirm')}
                    </Button>
                </Grid>
            )}

        </Grid>
    )
});

export default Register;