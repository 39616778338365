import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { ImageList, ImageListItem, Typography, Box, Link } from '@mui/material';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import MerchantItem from '@components/MerchantItem';
import NoData from '@layouts/NoData';

const Merchant = () => {
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [merchantListing, setMerchantListing] = useState([]);

    let location = useLocation();

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-shops?all=true`).then(response => {
            setLoading(false);
            if (response.status) {
                setMerchantListing(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', padding: "40px 20px 0px 20px" }}>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold', marginBottom: 10, marginTop: 50 }}>{`${t('title.availableMerchant')} (${_.size(merchantListing) || 0})`}</Typography>
            {
                _.size(merchantListing) > 0 ?
                    <ImageList style={{ width: '100%' }} cols={3} >
                        {
                            _.map(merchantListing, (data, dataIndex) => {
                                return (
                                    <ImageListItem key={dataIndex} style={{ margin: '0 auto' }}>
                                        <Link to={`/merchant-detail/${data?.id}`} state={{ from: location }} component={RouterLink} underline='none'>
                                            <Box style={{ marginBottom: "30px" , height:95 , width:95 }}>
                                                <MerchantItem shopLogo={data?.logo_image?.file_name} shopName={data.code} />
                                            </Box>
                                        </Link>
                                    </ImageListItem>
                                )
                            })
                        }
                    </ImageList>
                    :
                    <NoData />
            }
        </div>
    )
}

export default Merchant;