import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

const NoData = () => {
    const { t } = useTranslation();
    return (
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 50, width: "100%" }}>
            <div style={{ width: 300, height: 300 }}>
                <img src="images/gif/no-data.gif" alt="no-data" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
            </div>
            <Typography>{t('general.noData')}</Typography>
        </Box>
    )
}

export default NoData;