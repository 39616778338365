import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { currencyFormat } from '@utils/Tools';

import { Grid, Box, Typography, Button, IconButton, Dialog, FormControl, InputLabel, Link, Select, MenuItem } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import { TiInfoLarge } from "react-icons/ti";
import { MdKeyboardArrowDown, MdClose, MdPhone, MdEmail } from "react-icons/md";

import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';

import BranchItem from '@layouts/BranchItem';
import MerchantItem from '@components/MerchantItem';
import MerchantDescItem from '@components/MerchantDescItem';

const MerchantDetail = () =>{
    const { t, i18n } = useTranslation();
    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { id } = useParams();
    const theme = useTheme();
    const styles = useStyles();
    let location = useLocation();

    const [open, setOpen] = useState(false);
    const [shop, setShop] = useState({});
    const [branch, setBranch] = useState({});
    const [walletId, setWalletId] = useState('');
    const [state, setState] = useState('all');
    const [option, setOption] = useState({});
    const [userWallet, setUserWallet] = useState({});

    // -------------------------- APIS --------------------------------
    useEffect(() => {
        setLoading(true);
        getUrl(`/merchants/${id}`, {state: state, page: 'merchant-detail'}).then(response => {
            if (response.status) {
                setLoading(false);
                setShop(response.data.shop_info);
                setBranch(response.data.branch_list);
                setWalletId(response.data.wallet_coupon);
            } else {
                setLoading(false);
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/branches/addresses`).then(response => {
            setLoading(false);
            if (response.status) {
                let detail = [];
                _.map(response.addressList.stateList, list => {
                    if (list.country_code === 'MY') {
                        detail.push(list);
                    }
                })
                setOption(detail);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [state]);

    useEffect(() => {
        if (accessToken && shop?.id >0) {
            setLoading(true);
            getUrl(`/member-shop-wallet-balance`, { shop_id: shop?.id}).then(response => {
                if (response.status) {
                    setLoading(false);
                    setUserWallet(response.data)
                } else {
                    setLoading(false);
                    addAlert("", response?.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [accessToken , shop?.id]);

    // -------------------------- FUNCTIONS --------------------------------
    const handleChange = (event) => {
        setState(event.target.value);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let currStateList = [];
    _.map(shop?.branches, branch => {
        if (_.size(branch?.addresses) > 0) {
            let filterState = _.find(option, { id: parseInt(branch?.addresses?.state) });
            let checkRepeat = _.find(currStateList, { id: filterState?.id });
            if (checkRepeat === undefined) {
                currStateList.push(filterState);
            }
        }
    });

    return (
        <div className='dis-flex flex-col'>
            <div
                className={styles.bannerHeaderDesign}
                style={{
                    backgroundImage: `linear-gradient(to top, transparent 80%, rgb(0, 0, 0, 0.4)), 
                                      linear-gradient(to bottom, transparent 65%, rgb(0, 0, 0, 0.95)), 
                                      url('${shop?.banner_image?.file_name}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: 260,
                }}
            >
                <IconButton className={styles.infoBoxDesign} onClick={handleOpen} style={{ background: '#F7F7F7' }}>
                    <TiInfoLarge style={{ fontSize: 30 }} />
                </IconButton>
                <Grid container className='pos-relative dis-flex flex-m' style={{ top: 35 }}>
                    <Grid item xs={3}>
                        <MerchantItem shopLogo={shop?.logo_image?.file_name} />
                    </Grid>
                    <Grid item xs={5} style={{ color: '#fff', padding: '0 5px 20px 10px' }}>
                        <Typography variant='body1' style={{ lineHeight: 1.2 }}><b>{shop?.code}</b></Typography>
                        {
                            userWallet?.balance > 0 &&
                            <Typography variant='body2' style={{ wordBreak: 'break-word' }}>
                                {`${userWallet?.balance > 0 ? currencyFormat(parseFloat(userWallet?.balance) , userWallet?.wallet?.decimal): 0} ${ userWallet?.wallet?.name[i18n.language] ? userWallet?.wallet?.name[i18n.language]: userWallet?.wallet?.name?.en}`}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={4} className='dis-flex flex-r'>
                        {
                            userWallet?.balance > 0 &&
                            <Link className='dis-flex flex-c-m' to={`/select-branch?s=${btoa(shop?.id)}&w=${btoa(walletId)}`} state={{ from: location }} component={RouterLink} underline='none' style={{ fontSize: '75%', background: 'linear-gradient(97deg, #F4C13D 0%, #FFD364 103.61%)', boxShadow: 'none', color: '#000', borderRadius: 5, width: '100%', maxWidth: 85, height: 35 }}>
                                {t('shops.useWalletType', {type: userWallet?.wallet?.name[i18n.language] ? userWallet?.wallet?.name[i18n.language]: userWallet?.wallet?.name?.en})}
                            </Link>
                        }
                    </Grid>
                </Grid>
            </div>

            <div>
                <div className='p-lr-20 p-t-40 w-full'>
                    <Grid container className='flex-m'>
                        <Grid item xs={7}>
                            <Typography variant='body1'><b>{t('shops.shopBranch', { value: _.size(branch) })}</b></Typography>
                        </Grid>
                        <Grid item xs={5} className='flex-m flex-r'>
                            <FormControl fullWidth>
                                <SelectState
                                    value={state}
                                    label='shop'
                                    onChange={handleChange}
                                    variant='outlined'
                                >
                                    <MenuItem key={''} value={'all'}>{t('button.seeAll')}</MenuItem>
                                    {
                                        _.size(currStateList) > 0 &&
                                        _.map(currStateList, (detail, index) => (
                                            <MenuItem key={index} value={detail?.id}>{detail?.name}</MenuItem>
                                        ))
                                    }
                                </SelectState>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>

                <div className='s-full'>
                    {
                        _.size(branch) > 0 &&
                        _.map(branch, (item, index) => {
                            return (
                                <div key={index}>
                                    <div className='p-tb-10 p-lr-20'>
                                        <BranchItem branch={item} direction={false} />
                                    </div>
                                    <div style={{ height: 15, width: 444, boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.07) inset' }} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <MerchantDescItem shopInfo={shop} open={open} handleClose={handleClose} />
        </div>
    );
}

export default MerchantDetail;

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    logoBoxDesign: {
        maxWidth: 95,
        maxHeight: 95, 
        aspectRatio: 1 / 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F4F4F4', 
        objectFit: 'cover',
        borderRadius: 15,
        padding: 5,
        boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset, 3px 3px 4px 0px rgba(255, 255, 255, 0.25)',
    },
    infoBoxDesign: { 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
        borderRadius: 5, 
        width: 28, 
        height: 28,
        boxShadow: '1px 1px 3px 0px rgba(0, 0, 0, 0.19) inset, 2px 2px 2px 0px rgba(0, 0, 0, 0.11)',
        zIndex: 1100,
        padding: 5,
    },
    bannerHeaderDesign: {
        width: '100%',
        maxWidth: 444, 
        background: '#f4f4f4',
        padding: '15px 25px',
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-end', 
        justifyContent: 'space-between',
    }
}));

const SelectState = styled(Select)(({ theme }) => ({
    background: 'transparent',
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { 
        border: 0 
    },
    fontSize: 14,
}));