import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { currencyFormat } from '@utils/Tools';

import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { MdSearch } from "react-icons/md";

import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl } from '@utils/ApiAction';

import BranchItem from '@layouts/BranchItem';
import MerchantItem from '@components/MerchantItem';
import NoData from '@layouts/NoData';

const SelectBranch = () => {
    const { t , i18n } = useTranslation();
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    
    const [shopId, setShopId] = useState('');
    const [walletId, setWalletId] = useState('');
    const [searchParams] = useSearchParams();

    const [shop, setShop] = useState({});
    const [branches, setBranches] = useState({});
    const [branchId, setBranchId] = useState('');
    const [searchData, setSearchData] = useState('');
    const [ userWallet , setUserWallet] = useState({});

    let shop_id = '';
    let wallet_id = '';

    useEffect(() => {
        if (typeof(searchParams.get("s")) === 'string' && typeof(searchParams.get("w")) === 'string') {
            try {
                shop_id = parseInt(atob(searchParams.get("s")));
                wallet_id = parseInt(atob(searchParams.get("w")));

                if (!isNaN(shop_id) && !isNaN(wallet_id)) {
                    setShopId(shop_id);
                    setWalletId(wallet_id);
                    callApi();
                } else {
                    navigate('/');
                }
            } catch (error) {
                navigate('/');
            }
        } else {
            navigate('/');
        }
    }, [searchParams]);

    useEffect(() => {
        if (accessToken && shopId > 0) {
            setLoading(true);
            getUrl(`/member-shop-wallet-balance`, { shop_id: shopId }).then(response => {
                if (response.status) {
                    setLoading(false);
                    setUserWallet(response.data)
                } else {
                    setLoading(false);
                    addAlert("", response?.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [accessToken , shop?.id]);

    const callApi = () => {
        setLoading(true);
        getUrl(`/merchants/${shopId ? shopId : shop_id}`, {address: searchData}).then(response => {
            if (response.status) {
                setLoading(false);
                setShop(response.data.shop_info);
                setBranches(response.data.branch_list);
            } else {
                setLoading(false);
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
            navigate('/');
        });
    }

    const searchBranch = () => {
        callApi();
    }

    const handleBranchOnChange = (branch_id) => {
        if (branch_id === branchId) {
            setBranchId('');
        } else {
            setBranchId(branch_id);
        }
    }

    const handleNext = () => {
        navigate(`/payment?s=${btoa(shopId)}&b=${btoa(branchId)}&w=${btoa(walletId)}`, { state: { from: location } });
    }

    return (
        <div style={{ marginTop: 80 }}>
            <Box style={{ margin: "0px 25px" }}>
                <Box className="flex-m">
                    <div style={{ height: 95, width: 95 }}>
                        <MerchantItem shopLogo={shop?.logo_image?.file_name} />
                    </div>
                    <Box className="m-l-20">
                        <Typography variant="h5" style={{ fontWeight: "bold", color: "#2F2F2F" }}>{shop?.code}</Typography>
                        <Typography variant="body2" style={{ color: "#2F2F2F" }}>
                            {`${t('branch.balance')} : ${`${userWallet?.balance > 0 ? currencyFormat(parseFloat(userWallet?.balance) , userWallet?.wallet?.decimal): 0} ${ userWallet?.wallet?.name ? (userWallet?.wallet?.name[i18n.language] ? userWallet?.wallet?.name[i18n.language]: userWallet?.wallet?.name?.en) : '' }`}`}
                        </Typography>
                    </Box>
                </Box>
                <TextField
                    placeholder={t('branch.searchBranch')}
                    variant='outlined'
                    fullWidth
                    autoComplete='search'
                    name='search'
                    value={searchData}
                    onKeyDown={(e) => { if (e.key === 'Enter') { searchBranch(); } }}
                    onChange={({ target }) => setSearchData(target.value)}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={searchBranch} >
                                    <MdSearch color="#BEBEBE" />
                                </IconButton>
                            </InputAdornment>,
                    }}
                    style={{ margin: "20px 0px" }}
                />
                <Typography variant="body1" style={{ fontWeight: "bold" }}>{t('branch.selectBranch')}</Typography>
            </Box>
            {
                !loading ?
                    _.size(branches) > 0 ?
                        _.map(branches, (branch, key) => {
                            return (
                                <Box key={key} style={{ padding: '25px 24px', borderTop: key !== 0 ? 'dashed 1px #D2D2D2' : "none", backgroundColor: branch?.id === branchId ? theme.palette.primary.main : "transparent" }}>
                                    <Box onClick={() => handleBranchOnChange(branch?.id)}>
                                        <BranchItem branch={branch} direction={false} selected={branch?.id === branchId ? true : false} />
                                    </Box>
                                </Box>
                            )
                        })
                        : <NoData />
                    : null
            }
            <Box className="p-lr-24" style={{ margin: "50px 0px", textAlign: "center" }}>
                {
                    branchId
                        ?
                        <Button variant="contained" onClick={handleNext}>{t('button.next')}</Button>
                        :
                        <Button variant="contained" disabled>{t('button.selectBranch')}</Button>
                }
            </Box>
        </div>
    );
}

export default SelectBranch;