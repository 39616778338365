import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { currencyFormat } from '@utils/Tools';

import MerchantItem from '@components/MerchantItem';

const Receipt = props => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { shop, transaction, completedLink, chipSetting, subTitle } = props;
    let location = useLocation();
    let navigate = useNavigate();

    const handleComplete = () => {
        navigate(completedLink, { state: { from: location } });
    }

    return (
        <Box>
            <Box >
                <Box style={{ boxShadow: 'inset rgb(255 255 255) 4px 3px 4px, inset -2px -2px 10px 0 #8383832e', borderRadius: 15, padding: 10, backgroundColor: "#fff" }}>
                    <Box className="flex-c-m">
                        <Box className="flex-c-m" style={{ background: chipSetting.bgColor, borderRadius: 15, color: chipSetting.color, width: 250, height: 35, position: "relative", top: -20 }}>
                            {chipSetting.text}
                        </Box>
                    </Box>
                    <Box className="flex-m p-all-10" style={{ borderBottom: 'dashed 1px #D2D2D2' }}>
                        <div style={{ height: 95, width: 95 }}>
                            <MerchantItem shopLogo={shop.logo_image?.file_name} />
                        </div>
                        <Box className="m-l-20">
                            <Typography variant="h5" style={{ fontWeight: "bold", color: "#2F2F2F" }}>{shop.code}</Typography>
                            {
                                subTitle
                                &&
                                <Typography variant="body2" style={{ fontWeight: "bold", color: "#2F2F2F" }}>{subTitle}</Typography>
                            }
                            <Typography variant="body2" style={{ fontSize: 12 }}>{transaction.created_at}</Typography>
                        </Box>
                    </Box>
                    <Box className="p-all-10" style={{ borderBottom: 'dashed 1px #D2D2D2' }}>
                        <Typography variant="body2">{t('payment.transactionId')}</Typography>
                        <Typography variant="body2" style={{wordBreak:'break-all'}}>{transaction.ulid}</Typography>
                    </Box>
                    {
                        transaction.remark
                        ?
                    <Box className="p-all-10" style={{ borderBottom: 'dashed 1px #D2D2D2' }}>
                        {
                            transaction.remark?.member_remark
                            &&
                            <Box>
                                <Typography variant="body2">{t('payment.member_remark')}: </Typography>
                                <Typography variant="body2">{transaction.remark?.member_remark}</Typography>
                            </Box>
                        }
                        {
                            transaction.remark?.admin_remark
                            &&
                            <Box>
                                <Typography variant="body2">{t('payment.admin_remark')}: </Typography>
                                <Typography variant="body2">{transaction.remark?.admin_remark}</Typography>
                            </Box>
                        }
                    </Box>
                    :
                    null
                    }
                    <Box className="p-all-10">
                        <Box className="flex-sb-m">
                            <Typography variant="body2">{t('payment.totalAmount')}</Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold", color: transaction?.factor === -1 ? theme.palette.negativeAmount : 'inherit' }}>
                                {(transaction?.factor === 1 ? '+' : (transaction?.factor === -1 ? '-' : '')) + ' ' +  currencyFormat(parseFloat(transaction.amount))} {shop?.wallet?.name?.[i18n.language] ? shop?.wallet?.name?.[i18n.language] : shop?.wallet?.name?.en}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                completedLink
                    ?
                    <div className='pos-fixed max-w-full' style={{ width: 396, margin: '0 auto', bottom: 20, maxWidth: 'calc(100% - 48px)' }}>
                        <Button variant="contained" onClick={handleComplete}>{t('payment.completed')}</Button>
                    </div>
                    :
                    null
            }
        </Box>
    )
}

export default Receipt;