import { postUrl } from "@utils/ApiAction";
import useNotificationLoading from "@utils/useNotificationLoading";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation, useSearchParams } from "react-router-dom";

// MUI
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { makeStyles, useTheme, styled } from "@mui/styles";
import _ from "lodash";

export default function ResetPassword() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  let location = useLocation();

  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);

  const handleSuccessDialogOpen = () => {
    setSuccessDialogOpen(true);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  const [state, setState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    token: searchParams.get("token"),
  });

  const [option, setOption] = useState({
    password_show: false,
    password_confirmation_show: false,
  });

  const [inputErrors, setInputErrors] = useState();
  const { addAlert, setLoading } = useNotificationLoading();

  const resetPassword = () => {
    setLoading(true);
    postUrl("/reset-password", state)
      .then((response) => {
        setLoading(false);
        if (response.status) {
          addAlert("", response.message, "success", "");
          handleSuccessDialogOpen(); // Open the success dialog
        } else {
          setInputErrors(response.errors);
          let msg = response.message;
          _.map(response.errors, (value, index) => {
            msg += ` ${value}`;
          });
          addAlert("", msg, "error", "");
        }
      })
      .catch((error) => {
        setLoading(false);
        addAlert("", error.message || t("error.contactSupport"), "error", "");
      });
  };

  const handlePasswordShow = (name) => {
    setOption({ ...option, [name]: !option[name] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      style={{
        paddingLeft: 25,
        paddingRight: 25,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 80,
        overflowY: 'hidden',
        justifyContent: 'space-between',
        height: '85vh',
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h1" style={{ fontWeight: 700, fontSize: '25px', margin: '20px 0 20px 0' }}>
          {t("resetPassword.title")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={t("user.email")}
          fullWidth
          type="email"
          autoComplete="email"
          color="primary"
          value={state.email}
          onChange={({ target }) =>
            setState({ ...state, email: target.value })
          }
          helperText={
            inputErrors && inputErrors.email ? inputErrors.email : ""
          }
          error={inputErrors && inputErrors.email ? true : false}
          style={{ marginBottom: '15px' }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={t("user.newPassword")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handlePasswordShow("password_show")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {option?.password_show ? (
                    <MdVisibility
                      className="img-style"
                      style={{ color: theme.palette.inputIconColor }}
                    />
                  ) : (
                    <MdVisibilityOff
                      className="img-style"
                      style={{ color: theme.palette.inputIconColor }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          autoComplete="password"
          color="primary"
          value={state.password}
          type={option?.password_show ? "text" : "password"}
          onChange={({ target }) =>
            setState({ ...state, password: target.value })
          }
          helperText={
            inputErrors && inputErrors.password ? inputErrors.password : ""
          }
          error={inputErrors && inputErrors.password ? true : false}
          style={{ marginBottom: '15px' }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="filled"
          label={t("user.confirmNewPassword")}
          type={option?.password_confirmation_show ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    handlePasswordShow("password_confirmation_show")
                  }
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {option?.password_confirmation_show ? (
                    <MdVisibility className="img-style" />
                  ) : (
                    <MdVisibilityOff className="img-style" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          autoComplete="password"
          color="primary"
          value={state.password_confirmation}
          onChange={({ target }) =>
            setState({ ...state, password_confirmation: target.value })
          }
          helperText={
            inputErrors && inputErrors.password_confirmation
              ? inputErrors.password_confirmation
              : ""
          }
          error={
            inputErrors && inputErrors.password_confirmation ? true : false
          }
          style={{ marginBottom: '15px' }}
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: 'auto' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={resetPassword}
          fullWidth
          style={{ marginTop: '20px', width: '100%' }}
        >
          {t("resetPassword.button")}
        </Button>
      </Grid>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
        disableBackdropClick 
        maxWidth="xs" // Set the maximum width of the dialog
      >
        <DialogTitle style={{ padding: '20px' }}>
          {t("resetPassword.title")}
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <Typography variant="body1" gutterBottom>
            {t("resetPassword.successMessage")}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button
            component={RouterLink}
            to="/login"
            onClick={handleSuccessDialogClose}
            color="primary"
            variant="contained"
          >
            {t("resetPassword.backToLogin")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  
}));