import React from 'react';
import { PropTypes } from 'prop-types';

import { Box, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const MerchantItem = props => {
    const { shopLogo, shopName, imgContainerStyle } = props;
    const styles = useStyles();
    const theme = useTheme();

    return (
        <Box style={{ textAlign: "center" }}>
            <Box sx={imgContainerStyle} style={{ backgroundImage: `url(${shopLogo})` , margin:'0 auto', backgroundSize: 'cover', backgroundRepeat:'no-repeat',backgroundPosition: 'center'}}>
            </Box>
            {
                shopName &&
                <Typography variant="body2" style={{ marginTop: 5, color: '#000' }}>{shopName}</Typography>
            }
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
}));

// DEFINE DEFAULT PROPS TYPE
MerchantItem.propTypes = {
    shopLogo: PropTypes.string,
    imgContainerStyle: PropTypes.any,
};

// DEFINE DEFAULT PROPS VALUE
MerchantItem.defaultProps = {
    shopName: '',
    shopLogo: "/images/logos/logo.png",
    imgContainerStyle: {
        boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset, 3px 3px 4px 0px rgba(255, 255, 255, 0.25)',
        maxWidth: 95,
        maxHeight: 95,
        aspectRatio: 1 / 1,
        filter: 'drop-shadow(3px 3px 4px rgba(255, 255, 255, 0.25))',
        backgroundColor: '#F4F4F4',
        borderRadius: '15px',
        padding: '5px',
        display: 'flex',
    }
};

export default MerchantItem;