import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

// MUI
import { TextField, Typography, InputAdornment, Box, Card, CardContent, Button, Link, Grid } from '@mui/material';

import { MdPerson } from "react-icons/md";

import { makeStyles, useTheme } from '@mui/styles';

export default function ForgetPassword() {
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();

    const [state, setState] = useState({
        email: ''
    });
    const [inputErrors, setInputErrors] = useState();
    const { addAlert, setLoading } = useNotificationLoading();

    const resetPassword = () => {
        setLoading(true);
        postUrl('/forgot-password', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh', marginTop: '135px' }}>
            <div>
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ height: '50%' }}>
                            <img src="images/profile/forgot-password.png" style={{ height: '100%', margin: '10px 0 0px' }} alt="logo" />
                        </div>

                        <div>
                            <div>
                                <Typography variant="h1" style={{ fontWeight: 700, fontSize: '25px' }}>{t('forgotPassword.headerTitle')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: 400, fontSize: '14px' }}>{t('forgotPassword.desc')}</Typography>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{width: '80%', marginTop: 30}}>
                <TextField
                    variant="filled"
                    label={t('user.email')}
                    fullWidth
                    type="email"
                    autoComplete="email"
                    color='primary'
                    value={state.email}
                    onChange={({ target }) => setState({ ...state, email: target.value })}
                    className={styles.marginTop30}
                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                    error={inputErrors && inputErrors.email ? true : false}
                />
            </div>

            <div style={{ marginTop: 'auto', padding: '10px 0 15px 0', width:'80%' }}>
                <Button variant="contained" color="primary" onClick={resetPassword} fullWidth>{t('button.confirm')}</Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({

}));