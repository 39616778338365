import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector  } from 'react-redux';

import { Box, Button, SwipeableDrawer, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/styles';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { getUrl, postUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import MerchantItem from '@components/MerchantItem';
import Receipt from '@layouts/Receipt';

const Payment = () => {
    const { accessToken } = useSelector(state => state.general);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    let navigate = useNavigate();
    const [completed, setCompleted] = useState(false);
    const [state, setState] = useState({
        shop_id: '',
        branch_id: '',
        wallet_id: '',
        amount: '',
        remark: '',
        security_password: '',
        showPassword: false,
    });
    const [shop, setShop] = useState([]);
    const [walletBalance, setWalletBalance] = useState('');
    const [wallet, setWallet] = useState({});
    const [searchParams] = useSearchParams();
    const { addAlert, setLoading } = useNotificationLoading();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [inputErrors, setInputErrors] = useState();
    const [transaction, setTransaction] = useState([]);
    const [chipSetting, setChipSetting] = useState({
        bgColor: '#2D2D2D',
        color: '#fff',
        text: '',
    });
    const location  = window.location.href ;
    useEffect(() => {
        if(!accessToken){
            navigate('/login', { state: { from:{fullUrl : location} } });
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
            setLoading(true);
            if (typeof (searchParams.get("s")) === 'string' && typeof (searchParams.get("w")) === 'string') {
                try {
                    let shop_id = parseInt(atob(searchParams.get("s")));
                    let branch_id = parseInt(atob(searchParams.get("b")));
                    let wallet_id = parseInt(atob(searchParams.get("w")));

                    if (!isNaN(shop_id) && !isNaN(branch_id) && !isNaN(wallet_id)) {
                        setState({
                            ...state,
                            shop_id: shop_id,
                            branch_id: branch_id,
                            wallet_id: wallet_id,
                        });
                        let apiData = {
                            shop_id: shop_id,
                            branch_id: branch_id,
                            wallet_id: wallet_id,
                        }
                        getUrl(`/member-payment-balance`, apiData).then(response => {
                            setLoading(false);
                            if (response.status) {
                                setShop(response.data.shop);
                                setWalletBalance(response.data.wallet_balance);
                                setWallet(response.data.wallet);
                            } else {
                                navigate(`/`);
                            }
                        }).catch(error => {
                            setLoading(false);
                            addAlert('', error.message || t('error.contactSupport'), 'error', '');
                            navigate(`/`);
                        });
                    } else {
                        setLoading(false);
                        navigate(`/`);
                    }
                } catch (error) {
                    setLoading(false);
                    navigate(`/`);
                }
            } else {
                setLoading(false);
                navigate(`/`);
            }
        }
    }, [searchParams]);

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const insertSecurityPwd = () => {
        if (state.amount && state.remark) {
            setDrawerOpen(true);
        } else {
            addAlert('', t('payment.insertAmountRemark'), 'error', '');
        }
    }

    const confirmPayment = () => {
        if (state.security_password) {
            setLoading(true);
            postUrl(`/member-payment`, state).then(response => {
                setLoading(false);
                if (response.status) {
                    let bgColor = '';
                    let color = '';
                    let text = '';
                    switch (response.data.status) {
                        case 10: bgColor = '#F4C13D'; color = '#000'; text = t('payment.pending'); break;
                        case 20: bgColor = '#006638'; color = '#fff'; text = t('payment.completed'); break;
                        case 30: bgColor = '#2D2D2D'; color = '#fff'; text = t('payment.cancelled'); break;
                        default: bgColor = '#2D2D2D'; color = '#fff'; text = ''; break;
                    }
                    setChipSetting({ bgColor: bgColor, color: color, text: text });
                    setCompleted(true);
                    setTransaction(response.data);
                } else {
                    if (!response.errors.security_password) {
                        setDrawerOpen(false)
                    }
                    setInputErrors(response.errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert('', t('payment.insertSecurityPwd'), 'error', '');
        }
    }

    const paymentAmount = () => {
        return (
            <Box >
                <Box style={{ margin: "0px 24px" }}>
                    <Box className="flex-m m-b-30">
                        <div style={{ height: 95, width: 95 }}>
                            <MerchantItem shopLogo={shop.logo_image?.file_name} />
                        </div>
                        <Box className="m-l-20">
                            <Typography variant="h5" style={{ fontWeight: "bold", color: "#2F2F2F" }}>{shop?.code}</Typography>
                            <Typography variant="body2" style={{ fontWeight: "bold", color: "#2F2F2F" }}>{shop?.branches?.[0]?.branch_name}</Typography>
                            <Typography variant="body2" style={{ color: "#2F2F2F" }}>
                                {t('branch.balance') + ': ' + (walletBalance > 0 ? currencyFormat(parseFloat(walletBalance)) : 0) + ' ' + (wallet?.name ? (wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.['en']) : '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box style={{ margin: "20px 0px" }}>
                        <TextField
                            label={t('payment.amount')}
                            variant='filled'
                            fullWidth
                            autoComplete='amount'
                            value={state.amount}
                            onChange={({ target }) => setState({ ...state, amount: target.value })}
                            helperText={wallet?.name ? t('payment.amountNote', { points: currencyFormat(parseFloat(walletBalance)), type: (wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language] : wallet?.name?.['en']) }) : null}
                            name="amount"
                        />
                        {
                            inputErrors && inputErrors.amount
                                ?
                                <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.amount}</Typography>
                                :
                                null
                        }
                    </Box>
                    <TextField
                        label={t('payment.remarkNote')}
                        variant='filled'
                        fullWidth
                        autoComplete='remark'
                        value={state.remark}
                        onChange={({ target }) => setState({ ...state, remark: target.value })}
                        style={{ margin: "20px 0px" }}
                        name="remark"
                    />
                    {
                        inputErrors && inputErrors.remark
                            ?
                            <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.remark}</Typography>
                            :
                            null
                    }
                </Box>
                <div className='pos-fixed p-lr-24 max-w-full' style={{width:444, margin:'0 auto', bottom:20, zIndex:2}}>
                    <Button variant="contained" onClick={insertSecurityPwd}>{t('payment.confirmPayment')}</Button>
                </div>
                <SwipeableDrawer
                    anchor={"bottom"}
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    onOpen={() => setDrawerOpen(true)}
                    PaperProps={{ style: { position: "absolute", height: 220, maxWidth: "444px", borderRadius: "15px 15px 0px 0px", margin: "0 auto" } }}
                >
                    <Box className="m-lr-30 m-t-30">
                        <Typography variant="body1">{t('payment.enterSecurityPwd')}</Typography>
                        <TextField
                            variant='outlined'
                            fullWidth
                            type={state.showPassword ? 'text' : 'password'}
                            value={state.security_password}
                            onChange={({ target }) => setState({ ...state, security_password: target.value })}
                            style={{ margin: "10px 0px" }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            inputErrors && inputErrors.security_password
                                ?
                                <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.security_password}</Typography>
                                :
                                null
                        }

                        <div className='pos-fixed max-w-full' style={{ width: 'calc(414px - 30px)', maxWidth:'calc(100% - 60px)', margin: '0 auto', bottom: 20, zIndex:2 }}>
                            <Button variant="contained" onClick={confirmPayment}>{t('payment.confirmPayment')}</Button>
                        </div>
                       
                    </Box>
                </SwipeableDrawer>
            </Box>
        )
    }

    return (
        <div className='m-tb-80'>
            {
                completed
                    ?
                    <Box style={{ margin: "140px 24px 0px", height: "100%" }}>
                        <Receipt shop={shop} transaction={transaction} completedLink={`/wallet?s=${btoa(state?.shop_id)}&w=${btoa(state?.wallet_id)}`} chipSetting={chipSetting} subTitle={shop?.branches?.[0]?.branch_name} />
                    </Box>
                    :
                    paymentAmount()
            }
        </div>
    );
}

export default Payment;