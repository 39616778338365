import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import MerchantSlider from '@pages/Home/MerchantSlider';
import WalletListing from '@pages/Home/WalletListing';

import { Link } from '@mui/material';

import { Link as RouterLink, useLocation } from 'react-router-dom';



const Home = () => {
    const { t, i18n } = useTranslation();
    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const [walletListing, setWalletListing] = useState([]);
    const [merchantListing, setMerchantListing] = useState([]);
    let location = useLocation();

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-wallets`).then(response => {
            setLoading(false);
            if (response.status) {
                setWalletListing(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        getUrl(`/member-shops?all=false`).then(response => {
            setLoading(false);
            if (response.status) {
                setMerchantListing(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 80 }}>
            <div className='p-lr-20 p-t-10'>
                <img src={`images/banner/rewardeveryday-${i18n.resolvedLanguage}.png`} alt="banner" className='w-full bor-15' style={{boxShadow:'0px 4px 4px 0px rgba(27, 83, 165, 0.24)'}} />
            </div>
            {accessToken ?
            null
            :
            <div className='p-lr-24 p-t-24 flex-sb-m'>
                <Link to="/login" state={{ from: location }} component={RouterLink} underline='none' style={{width:'47%'}}>
                    <div className='txt-center w-full p-lr-10 p-tb-10 pointer' style={{
                        borderRadius: 8, background: 'linear-gradient(91deg, #F4C13D -7.08%, #FFD466 99.62%)',
                        boxShadow: '3px 3px 4px 0px #FFF, -3px -3px 4px 0px rgba(0, 0, 0, 0.09) inset', color:'#232323'
                    }}>
                        {t('button.login')}
                    </div>
                </Link>
                <Link to="/login" state={{ from: location }} component={RouterLink} underline='none'  style={{width:'47%'}}>
                <div className='txt-center w-full p-lr-10 p-tb-10 pointer clwhite' style={{
                    borderRadius: 8, background: 'linear-gradient(91deg, #1B53A5 -7.08%, #2079CB 99.62%)',
                    boxShadow: '3px 3px 4px 0px #FFF, -3px -3px 4px 0px rgba(0, 0, 0, 0.09) inset'
                }}>
                    {t('button.register')}
                </div>
                </Link>
            </div>
            }

            
            {accessToken && <WalletListing listing={walletListing} />}
            {_.size(merchantListing) > 0 && <div style={{marginTop: 30, marginBottom: 30}}><MerchantSlider listing={merchantListing} /></div>}

            <div className='p-lr-24 p-b-50'>
                <div className='p-b-20'>
                    <img src={`images/banner/mansion9-en.png`} alt="banner" className='w-full' />
                </div>
                <div>
                    <img src={`images/banner/macaboro-en.png`} alt="banner" className='w-full' />
                </div>
            </div>
        </div>
    );
}

export default Home;