import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import WalletItem from '@components/WalletItem';

const WalletListing = props => {
    const { listing } = props;
    const { t, i18n } = useTranslation();

    let wallet_bg_color = _.split(process.env.REACT_APP_WALLET_BG_COLOR, /[\[\]", ']+/).filter(str => str !== '');

    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', padding: 20 }}>
            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{`${t('title.myWallet')} (${_.size(listing) || 0})`}</Typography>
            {
                _.size(listing) > 0 ?
                 _.map(listing, (data, key) => {
                    let setBgIndex = key % 3;
                    return (
                        <div key={key} style={{ marginTop: '45px', width: '100%' }}>
                            <WalletItem
                                shopName={data?.shop?.code}
                                shopLogo={data?.shop?.logo_image?.file_name}
                                walletValue={data?.balance}
                                walletDecimal={data?.wallet?.decimal} 
                                walletType={data?.wallet?.name?.[i18n.language] ? data?.wallet?.name?.[i18n.language] : data?.wallet?.name?.en}
                                backgroundColor={wallet_bg_color?.[setBgIndex]}
                                labelColor={setBgIndex === 0 ? '#000000' : '#ffffff'}
                                urlLink={`/wallet?s=${btoa(data?.shop?.id)}&w=${btoa(data?.wallet?.id)}`}
                            />
                        </div>
                    )
                })
                :
                <div style={{ marginTop: '28px', width: '100%' }}>
                    <WalletItem
                        shopName={""}
                        shopLogo={'/images/logos/logo.png'}
                        walletValue={0}
                        walletDecimal={2} 
                        backgroundColor={wallet_bg_color?.[0]}
                        labelColor={'#ffffff'}
                    />
                </div>
            }
        </div>
    );
}

export default WalletListing;