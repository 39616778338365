import axios from 'axios';

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
    },
});

export { API };