import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, SwipeableDrawer } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';
import { MdArrowForward } from "react-icons/md";

import MerchantItem from '@components/MerchantItem';

const BranchItem = props => {
    const { branch, direction, selected } = props;
    const theme = useTheme();
    const styles = useStyles();
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) { return; }
        setDrawerOpen(open);
    };

    return (
        <Box>
            <Box className="flex-m">
                <div style={{ height: 95, width: 95 }}>
                    <MerchantItem shopLogo={`${_.size(branch?.picture?.file_name) > 0 ? branch?.picture?.file_name : '/images/logos/logo.png'}`} imgContainerStyle={{ boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset, 3px 3px 4px 0px rgb(255 255 255)', height: 77, aspectRatio: 1 / 1, filter: 'drop-shadow(3px 3px 4px rgba(255, 255, 255, 0.25))', backgroundColor: '#F4F4F4', borderRadius: '15px', padding: '5px', display: 'flex' }} />
                </div>
                <Box className="p-l-20" style={{ width: "75%" }} >
                    <Typography style={{ fontSize: "14px", fontWeight: "bold", color: "#434343" }}>{branch?.branch_name}</Typography>
                    <Typography className="p-t-5" style={{ fontSize: "11px", lineHeight: "1.3", color: "#434343" }}>{branch?.addresses?.full_address}</Typography>
                    <Box className="flex-sb-m p-t-10">
                        <Typography style={{ fontSize: "12px", color: "#434343" }}>{branch?.branch_mobile}</Typography>
                        {
                            !direction && selected &&
                            <Typography style={{ fontSize: "12px", color: "#434343" }}>{t('branch.selected')}</Typography>
                        }
                        {
                            direction && !selected &&
                            <Box className="flex-c-b" style={{ cursor: 'pointer' }} onClick={toggleDrawer(true)}>
                                <Typography style={{ fontSize: "12px", color: "#434343" }}>{t('branch.direction')}</Typography>
                                <MdArrowForward className="m-l-10" />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <SwipeableDrawer
                anchor={"bottom"}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableScrollLock={true}
                PaperProps={{ style: { position: "absolute", height: '35vh', maxWidth: "444px", borderRadius: "15px 15px 0px 0px", margin: "0 auto" } }}
            >
                <Box className="m-lr-40 m-t-40">
                    <Typography variant="body2" style={{ fontWeight: "bold", textAlign: "center" }}>{t('branch.directionOpenWith')}</Typography>
                    <Box className="flex-sb-m m-tb-30">
                        <Box className="flex-c-m" style={{ width: "50%" }}>
                            <Box>
                                <div style={{ width: 85, height: 85, objectFit: "cover" }}>
                                    <img style={{ width: "100%", height: "100%", borderRadius: 10 }} src="../images/maps/google.png" alt="google-map" />
                                </div>
                                <Typography variant="body2" style={{ textAlign: "center", paddingTop: 10 }}>{t('branch.googleMap')}</Typography>
                            </Box>
                        </Box>
                        <Box className="flex-c-m" style={{ width: "50%" }}>
                            <Box>
                                <div style={{ width: 85, height: 85, objectFit: "cover" }}>
                                    <img style={{ width: "100%", height: "100%", borderRadius: 10 }} src="../images/maps/waze.png" alt="waze" />
                                </div>
                                <Typography variant="body2" style={{ textAlign: "center", paddingTop: 10 }}>{t('branch.waze')}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
}));

export default BranchItem;