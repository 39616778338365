import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';

export default function Login() {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  let location = useLocation();
  
  return (
    <Box
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main
      }}
    >
      {/* Logo centered vertically */}
      <Box>
        <img src="images/logos/logo-bg.png" style={{ width: 220, marginTop: 100 }} alt={t('altText.logo')} />
      </Box>
      <Box>
        <img src="images/logos/logo-text.png" style={{ width: 220, marginTop: 20 }} alt={t('altText.logo')} />
      </Box>
  
      {/* Spacer to push buttons to the bottom */}
      <Box style={{ flex: 1 }} />
  
      {/* Buttons at the bottom */}
      <Grid container spacing={2} justifyContent="center" style={{ padding: '20px 20px' }}>
        <Grid item xs={12}>
          <Link underline='none' to={`/login`} component={RouterLink} state={{ from: location }}>
            <Button variant="contained" size="large" className={styles.button}>
              {t('button.login')}
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link underline='none' to={`/register`} component={RouterLink} state={{ from: location }}>
            <Button variant="contained" size="large" className={styles.button}>
              {t('button.register')}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
  
const useStyles = makeStyles(theme => ({
  button: {
    color: 'black',
    width: '100%',
    background: 'white',
    '&:hover':{
      background:'white'
    }
  },
}));  