import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Hidden, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import MerchantItem from '@components/MerchantItem';

const WalletItem = props => {
    const { shopName, shopLogo, walletValue, walletDecimal, walletType, backgroundColor, labelColor, children, urlLink } = props;
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();

    const handleOnClick = () => {
        if (urlLink) {
            navigate(urlLink, { state: { from: location } });
        }
    }

    return (
        <Box onClick={handleOnClick} className="pos-relative" style={{ cursor: urlLink ? "pointer" : "default", minHeight: children ? 175 : 120 }}>
            <div className="pos-absolute">
                <img style={{ width: "100%", height: "100%" }} src={`/images/walletBg/${backgroundColor.slice(1)}.png`} alt="wbg" />
                <div className={styles.rectangle} style={{ backgroundColor: backgroundColor, marginTop: -10, minHeight: children ? 85 : 70 }} />
            </div>
            <div className={`${styles.rectangle} pos-absolute m-t-10`}>
                <div className="flex-sb flex-b m-lr-30 h-full" style={{ minHeight: 70 }}>
                    <div className="flex-col-l-m" style={{ width: 'auto' }}>
                        <Typography style={{ color: labelColor, textTransform: "uppercase" }}>
                            {t('wallet.balance')}
                        </Typography>
                        {/* <Typography style={{ color: labelColor }}>
                            {`${shopName} ${t('wallet.balance')}`}
                        </Typography> */}
                        <Typography variant='h6' style={{ color: labelColor, wordBreak: 'break-all', width: '100%' }}>
                            <b>{walletValue ? (parseFloat(walletValue)).toFixed(walletDecimal) : (parseFloat(0)).toFixed(walletDecimal)} {walletType}</b>
                        </Typography>
                    </div>
                    <div style={{ height: 95, width: 95 }} className="shop-logo">
                        <MerchantItem shopLogo={shopLogo} />
                    </div>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    rectangle: {
        display: 'inline-block',
        width: '100%',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        // overflow: 'hidden',
    },
    logoContainerStyle: {
        boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset, 3px 3px 4px 0px rgba(255, 255, 255, 0.25)',
        width: 90,
        height: 90,
        filter: 'drop-shadow(3px 3px 4px rgba(255, 255, 255, 0.25))',
        backgroundColor: '#F4F4F4',
        borderRadius: 15,
        padding: 10,
        marginBottom: 30,
    },
    imageStyle: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    }
}));

// DEFINE DEFAULT PROPS TYPE
WalletItem.propTypes = {
    shopName: PropTypes.string,
    shopLogo: PropTypes.string,
    walletValue: PropTypes.string,
    walletDecimal: PropTypes.number,
    backgroundColor: PropTypes.string,
    labelColor: PropTypes.string,
    children: PropTypes.node,
};

// DEFINE DEFAULT PROPS VALUE
WalletItem.defaultProps = {
    shopName: '',
    shopLogo: "images/logos/logo.png",
    walletValue: '0',
    walletDecimal: 0,
    backgroundColor: '#F4C13D',
    labelColor: '#ffffff',
};

export default WalletItem;