// AUTH
import Login from '@auths/Login';
import Register from '@auths/Register';
import ForgetPassword from '@auths/ForgetPassword';
import ResetPassword from '@auths/ResetPassword';
import SplashScreen from '@auths/SplashScreen';

// GUEST
import Home from '@pages/Home';
import Merchant from '@pages/Merchant';
import MerchantDetail from '@pages/Merchant/MerchantDetail';

// USER
import Profile from '@pages/Profile/BasicInfoProfile';
import PasswordProfile from '@pages/Profile/PasswordProfile';
import SecurityPasswordProfile from '@pages/Profile/SecurityPassword';
import InviteFriends from '@pages/Profile/InviteFriends';
import PaymentSelectBranch from '@pages/Payment/SelectBranch';
import Payment from '@pages/Payment/Payment';
import Transfer from '@pages/Transfer';

// WALLET
import Wallet from '@pages/Wallet/Wallet';

// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [
    {
        path: "/profile",
        component: <Profile />,
        title:'title.editProfile',
        showBackIcon: true,
        showLogo: false,
        showTitle: true,
        showShortcut: false,
    },
    {
        path: "/password-profile",
        component: <PasswordProfile />,
        title:'title.changePassword',
        showBackIcon: true,
        showLogo: false,
        showTitle: false,
        showShortcut: false,
    },
    {
        path: "/security-password-profile",
        component: <SecurityPasswordProfile />,
        title:'title.changeSecurityPassword',
        showBackIcon: true,
        showLogo: false,
        showTitle: false,
        showShortcut: false,
    },
    {
        path: "/invite-friends",
        component: <InviteFriends />,
        title:'title.inviteFriends',
        showBackIcon: true,
        showLogo: false,
        showTitle: false,
        showShortcut: true,
    },
    {
        path: "/select-branch",
        component: <PaymentSelectBranch />,
        title:'title.payment',
        showBackIcon: true,
        showLogo: false,
        showTitle: true,
        showShortcut: false,
    },
    {
        path: "/transfer",
        component: <Transfer />,
        title:'title.transfer',
        showBackIcon: false,
        showLogo: true,
        showTitle: false,
        showShortcut: true,
    },
    {
        path: "/wallet",
        component: <Wallet />,
        title:'',
        showBackIcon: true,
        showLogo:false,
        showTitle:false,
        showShortcut:true,
    }
];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/",
        component: <Home />,
        title:'title.home',
        showBackIcon: false,
        showLogo:true,
        showTitle:false,
        showShortcut:true,
    },
    {
        path: "/merchants",
        component: <Merchant />,
        title:'title.merchants',
        showBackIcon: true,
        showLogo:false,
        showTitle:false,
        showShortcut:true,
    },
    {
        path: "/merchant-detail/:id",
        component: <MerchantDetail />,
        title:'title.merchantDetail',
        showBackIcon: true,
        showLogo:false,
        showTitle:false,
        showShortcut:false,
    },
    {
        path: "/payment",
        component: <Payment />,
        title:'title.payment',
        showBackIcon: true,
        showLogo: false,
        showTitle: true,
        showShortcut: false,
    },
    {
        path: "/login",
        component: <Login />,
        title:'title.login',
        showBackIcon: true,
        showLogo: false,
        showTitle: false,
        showShortcut: false,
    },
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [
    {
        path: "/register",
        component: <Register />,
        title:'title.register',
        showBackIcon: true,
        showLogo: false,
        showTitle: false,
        showShortcut: false,
    },
    {
        path: "/forgot-password",
        component: <ForgetPassword />,
        title:'forgotPassword.title',
        showBackIcon: true,
        showLogo: false,
        showTitle: false,
        showShortcut: false,
    },
    {
        path: "/reset-password",
        component: <ResetPassword />,
        title:'forgotPassword.title',
        showBackIcon: true,
        showLogo: false,
        showTitle: false,
        showShortcut: false,
    },
    {
        path: "/splash-screen",
        component: <SplashScreen />,
        title:'title.splashScreen',
        showBackIcon: false,
        showLogo: false,
        showTitle: false,
        showShortcut: false,
    },
];