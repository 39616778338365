import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Button, SwipeableDrawer, TextField, Typography, IconButton, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/styles';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { getUrl, postUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import WalletItem from '@components/WalletItem';
import Receipt from '@layouts/Receipt';

const Transfer = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    let navigate = useNavigate();
    const { addAlert, setLoading } = useNotificationLoading();

    const [completed, setCompleted] = useState(false);
    const [state, setState] = useState({
        shop_id: '',
        wallet_id: '',
        username: '',
        amount: '',
        remark: '',
        security_password: '',
        showPassword: false,
    });
    const [shop, setShop] = useState([]);
    const [wallet, setWallet] = useState({});
    const [walletBalance, setWalletBalance] = useState('');
    const [searchParams] = useSearchParams();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [inputErrors, setInputErrors] = useState();
    const [transaction, setTransaction] = useState([]);
    const [chipSetting, setChipSetting] = useState({
        bgColor: '#006638',
        color: '#fff',
        text: t('transfer.completed'),
    });

    useEffect(() => {
        setLoading(true);
        if (typeof(searchParams.get("s")) === 'string' && typeof(searchParams.get("w")) === 'string') {
            try {
                let shop_id = parseInt(atob(searchParams.get("s")));
                let wallet_id = parseInt(atob(searchParams.get("w")));

                if (!isNaN(shop_id) && !isNaN(wallet_id)) {
                    setState({
                        ...state,
                        shop_id: shop_id,
                        wallet_id: wallet_id,
                    });
                    let apiData = {
                        shop_id: shop_id,
                        wallet_id: wallet_id,
                    }
                    getUrl(`/member-transfer-balance`, apiData).then(response => {
                        setLoading(false);
                        if (response.status) {
                            setShop(response.data.shop);
                            setWalletBalance(response.data.wallet_balance);
                            setWallet(response.data.wallet);
                        } else {
                            navigate(`/`);
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                        navigate(`/`);
                    });
                } else {
                    setLoading(false);
                    navigate(`/`);
                }
            } catch (error) {
                setLoading(false);
                navigate(`/`);
            }
        } else {
            setLoading(false);
            navigate(`/`);
        }
    }, [searchParams]);

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const insertSecurityPwd = () => {
        if (state.username && state.amount && state.remark) {
            setDrawerOpen(true);
        } else {
            addAlert('', t('transfer.insertAmountRemark'), 'error', '');
        }
    }

    const confirmTransfer = () => {
        if (state.security_password) {
            setLoading(true);

            let apiData = {
                'username': state?.username,
                'from_wallet_id': state?.wallet_id,
                'to_wallet_id': state?.wallet_id,
                'shop_id': state?.shop_id,
                'amount': state?.amount,
                'remark': state?.remark,
                'security_password': state?.security_password,
            }

            postUrl(`/wallets/transfer`, apiData).then(response => {
                setLoading(false);
                if (response.status) {
                    setCompleted(true);
                    setTransaction(response.data);
                } else {
                    if (!response.errors.security_password) {
                        setDrawerOpen(false)
                    }
                    setInputErrors(response.errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert('', t('payment.insertSecurityPwd'), 'error', '');
        }
    }

    const transferAmount = () => {
        return (
            <Box>
                <Box style={{ margin: "0px 25px" }}>
                    <Box className="m-b-30">
                        <WalletItem shopName={shop?.code} shopLogo={shop?.logo_image?.file_name} walletValue={walletBalance} walletDecimal={wallet?.decimal} walletType={wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language]: wallet?.name?.en} backgroundColor='#F4C13D' labelColor='#000000' />
                    </Box>
                    <Typography variant='body1'><b>{t('transfer.title', {type: wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language]: wallet?.name?.en})}</b></Typography>
                    <Box style={{ margin: "20px 0px" }}>
                        <TextField
                            label={t('transfer.transferTo')}
                            variant='filled'
                            fullWidth
                            autoComplete='username'
                            name='username'
                            value={state?.username}
                            onChange={({ target }) => setState({ ...state, username: target.value })}
                        />
                        {
                            inputErrors && inputErrors.username
                                ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.username}</Typography>
                                : inputErrors?.allow_self
                                    ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_self}</Typography>
                                    : inputErrors?.allow_others
                                        ? <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.allow_others}</Typography>
                                        : null
                        }
                    </Box>
                    <Box style={{ margin: "20px 0px" }}>
                        <TextField
                            label={t('transfer.amount')}
                            variant='filled'
                            fullWidth
                            autoComplete='amount'
                            name='amount'
                            value={state?.amount}
                            onChange={({ target }) => setState({ ...state, amount: target.value })}
                            helperText={wallet?.name?.[i18n.language] ? t('transfer.amountNote', { points: currencyFormat(parseFloat(walletBalance)), type: wallet?.name?.[i18n.language] ? wallet?.name?.[i18n.language]: wallet?.name?.en }) : null}
                        />
                        {
                            inputErrors && inputErrors.amount
                                ?
                                <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.amount}</Typography>
                                :
                                null
                        }
                    </Box>
                    <Box style={{ margin: "20px 0px" }}>
                        <TextField
                            label={t('transfer.remarkNote')}
                            variant='filled'
                            fullWidth
                            autoComplete='remark'
                            name='remark'
                            value={state?.remark}
                            onChange={({ target }) => setState({ ...state, remark: target.value })}
                        />
                        {
                            inputErrors && inputErrors.remark
                                ?
                                <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.remark}</Typography>
                                :
                                null
                        }
                    </Box>
                </Box>
                <Box className="flex-c-m">
                    <Button variant="contained" style={{ position: "absolute", bottom: 30 }} onClick={insertSecurityPwd}>{t('transfer.confirmTransfer')}</Button>
                </Box>

                <SwipeableDrawer
                    anchor={"bottom"}
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    onOpen={() => setDrawerOpen(true)}
                    PaperProps={{ style: { position: "absolute", height: '35vh', maxWidth: "444px", borderRadius: "15px 15px 0px 0px", margin: "0 auto" } }}
                >
                    <Box className="m-lr-30 m-t-30">
                        <Typography variant="body1">{t('transfer.enterSecurityPwd')}</Typography>
                        <TextField
                            variant='outlined'
                            fullWidth
                            type={state.showPassword ? 'text' : 'password'}
                            value={state?.security_password}
                            onChange={({ target }) => setState({ ...state, security_password: target.value })}
                            style={{ margin: "10px 0px" }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            inputErrors && inputErrors.security_password
                                ?
                                <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>{inputErrors.security_password}</Typography>
                                :
                                null
                        }
                        <Box className="flex-c-m">
                            <Button variant="contained" style={{ position: "absolute", bottom: 30 }} onClick={confirmTransfer}>{t('transfer.confirmTransfer')}</Button>
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </Box>
        )
    }

    return (
        <div style={{ marginTop: 80 }}>
            {completed ? 
            <Box style={{ margin: "140px 25px 0px", height: "100%" }}>
                <Receipt shop={shop} transaction={transaction} completedLink={`/wallet?s=${btoa(state?.shop_id)}&w=${btoa(state?.wallet_id)}`}  chipSetting={chipSetting} subTitle={transaction?.details?.to} />
            </Box>
            : transferAmount()}
        </div>
    );
}

export default Transfer;