import React, { useState, useRef } from 'react';
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { putUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';;

const InviteFriends = () => {
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const styles = useStyles();
    const { t } = useTranslation();
    const { username } = useSelector(state => state.user);
    const shareLink = useRef(null);

    const copyLink = () => {
        var textField = document.createElement('textarea');
        textField.innerText = `${window.location.origin}/register?r=${btoa(username)}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        addAlert('', t('inviteFriend.copySuccess'), 'success', '');
    };

    return (
        <Grid item xs={12}
            style={{
                paddingLeft: 25,
                paddingRight: 25,
                display: 'flex',
                flexDirection: 'column',
                marginTop: 200,
                overflowY: 'hidden',
                justifyContent: 'space-between',
                alignItems:'stretch',
            }}
            >
            <Grid
                item
                xs={12}
                style={{
                    background: '#F7F7F7',
                    borderRadius: 10,
                    boxShadow: '3px 3px 6px 0px #FFF, 3px 3px 2px 0px rgba(0, 0, 0, 0.07) inset',
                    padding: '20px',
                    textAlign: 'center',
                    marginBottom: 30
                }}
            >
                {/* title */}
                <Grid item xs={12} style={{ marginBottom: 20, marginTop: 20 }}>
                    <Typography variant="h1" style={{ fontWeight: 700, fontSize: '18px', lineHeight: '21.78px' }}>
                        {t("title.inviteFriend")}
                    </Typography>
                </Grid>

                {/* QRCode */}
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <QRCode
                        size='178'
                        value={`${window.location.origin}/register?r=${btoa(username)}`}
                        renderAs="svg"
                        fgColor={'black'}
                    />
                </Grid>

                {/* URlink */}
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography ref={shareLink} style={{
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        marginLeft: '15px',}}>
                        {`${window.location.origin}/register?r=${btoa(username)}`}
                    </Typography>
                </Grid>

                {/* ShareButton */}
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Button 
                        onClick={copyLink}
                        variant="contained"
                    >
                        {t("inviteFriend.button")}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({

}));

export default InviteFriends