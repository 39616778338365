import { changeLanguage } from '@actions';
import { private_routes, public_restricted_routes, public_routes } from '@configs/Router';
import { AppBar, Avatar, IconButton, Link, Toolbar, Tooltip, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronLeft } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, matchRoutes } from 'react-router-dom';

import DrawerBar from '@layouts/DrawerBar';

const INITIAL_STATE = { showBackIcon: false, showLogo: false, showTitle: false, showShortcut: false, title: null };

const LINK_NO_SHADOW_BOX = ["/splash-screen", "/reset-password", "/forgot-password", "/register", "/login"]

const TitleBar = memo((props) => {
    // const { showTitle } = props;
    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();

    const { i18n, t } = useTranslation();
    const { accessToken } = useSelector(state => state.general);
    const { name, profile_image } = useSelector(state => state.user);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [changeColor, setChangeColor] = useState(false);
    const [option, setOption] = useState(INITIAL_STATE);

    const open = Boolean(anchorEl);
    let from = location.state?.from?.pathname;
    let current_path = location?.pathname;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (accessToken) {
            // if(email === null){
            //     navigate('/me/profile');
            // }
        }
        // eslint-disable-next-line
    }, [accessToken]);

    useEffect(() => {
        // let public_restricted_detail = _.find(public_restricted_routes, { 'path': current_path });
        // let public_detail = _.find(public_routes, { 'path': current_path });
        // let private_detail = _.find(private_routes, { 'path': current_path });

        let public_restricted_detail = matchRoutes(public_restricted_routes, current_path)?.[0]?.['route'];
        let public_detail = matchRoutes(public_routes, current_path)?.[0]?.['route'];
        let private_detail = matchRoutes(private_routes, current_path)?.[0]?.['route'];

        if (_.size(public_restricted_detail) > 0) {
            setOption({ ...option, showBackIcon: public_restricted_detail?.showBackIcon, showLogo: public_restricted_detail?.showLogo, showTitle: public_restricted_detail?.showTitle, showShortcut: public_restricted_detail?.showShortcut, title: t(public_restricted_detail?.title) });
        } else if (_.size(public_detail) > 0) {
            setOption({ ...option, showBackIcon: public_detail?.showBackIcon, showLogo: public_detail?.showLogo, showTitle: public_detail?.showTitle, showShortcut: public_detail?.showShortcut, title: t(public_detail?.title) });
        } else if (_.size(private_detail) > 0) {
            setOption({ ...option, showBackIcon: private_detail?.showBackIcon, showLogo: private_detail?.showLogo, showTitle: private_detail?.showTitle, showShortcut: private_detail?.showShortcut, title: t(private_detail?.title) });
        }else{
            setOption(INITIAL_STATE);
        }

    }, [current_path,t]);

    const changeSystemLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeLanguage(lang));
        handleClose();
    };

    const goBack = () => {
        if (accessToken && from === "/login") {
            navigate('/');
        } else if (from === null) {
            navigate('/login');
        } else {
            navigate(-1);
        }
    }

    const changeBackgroundColor = () => {
        if (window?.scrollY > 0) {
            setChangeColor(true);
        } else {
            setChangeColor(false);
        }
    }
    window.addEventListener('scroll', changeBackgroundColor);

    return (
        <AppBar style={{boxShadow: _.includes(LINK_NO_SHADOW_BOX, current_path) ? '':'0px 4px 4px 0px rgba(0, 0, 0, 0.05)', borderRadius: _.includes(LINK_NO_SHADOW_BOX, current_path)? '':'0 0 30px 30px'}} sx={{ maxWidth: 'inherit', width: '100%', right: 'auto', backgroundColor: changeColor ? theme.palette.backgroundColor : 'transparent', backdropFilter:'blur(1px)' }} position='fixed'>
            <Toolbar className='dis-flex flex-sb-m'>
                {
                    option?.showBackIcon &&
                    <IconButton onClick={goBack} style={{background: '#f5f5f554', backdropFilter: 'blur(6px)'}}><BiChevronLeft className="img-style" /></IconButton>

                }
                {
                    option?.showLogo &&
                    <div style={{ flexGrow: 1 }}>
                        <Link to="/" state={{ from: location }} component={RouterLink} underline='none'>
                            {/* <div style={{ width: 50, display: 'flex', justifyContent: 'center' }}>
                                <img src="images/logos/logo-text-color.png" style={{ height: '100%', width: '100%', padding: 5, objectFit: 'cover' }} alt="logo" />
                            </div> */}
                            <div className='flex-m'>
                                <img src="images/logos/logo-text-color.png" alt="logo" style={{width:'130px'}}/>
                            </div>
                        </Link>
                    </div>
                }
                {
                    option?.showTitle &&
                    <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }} className='txt-center'>
                        <b>{option?.title}</b>
                    </Typography>
                }
                {
                    option?.showShortcut &&
                    <div className='flex-m'>
                        <Tooltip title={t('title.setting')} arrow><IconButton style={{marginRight:15}} onClick={() => setOpenDrawer(true)} ><FiSettings className="img-style" /></IconButton></Tooltip>
                        {/* <Tooltip title={t('general.language')} arrow><IconButton onClick={handleClick} ><GoGlobe className="img-style" /></IconButton></Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            slotProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={() => changeSystemLanguage('en')} >{t('general.en')}</MenuItem>
                            <MenuItem onClick={() => changeSystemLanguage('cn')}  >{t('general.cn')}</MenuItem>
                        </Menu> */}
                        {
                            accessToken &&
                            <Link to="/profile" state={{ from: location }} component={RouterLink} underline='none' >
                                {
                                    profile_image ?
                                        <Avatar className={styles.avatarStyle} src={profile_image?.file_name} />
                                        :
                                        (
                                            name ?
                                                <Avatar className={styles.avatarStyle}>
                                                    <Typography variant='h4'>{name.split(' ')[0][0]}</Typography>
                                                </Avatar>
                                                :
                                                <Avatar className={styles.avatarStyle}  >
                                                    <img src="images/profile/profile-image.png" style={{ height: '100%', width: '100%' }} alt="profile-logo" />
                                                </Avatar>
                                        )
                                }
                            </Link>
                        }
                    </div>
                }

            </Toolbar>
            <DrawerBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        </AppBar>
    )
});

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        height: 48,
        width: 48,
        flexShrink: 0,
        objectFit: 'cover',
    },
}));

// TitleBar.defaultProps = {
//     showTitle: true,
// };

export default TitleBar;